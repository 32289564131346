/* eslint-disable default-param-last */
import { SET_COMPONENTS, SET_COMPONENT, UPDATE_COMPONENT, DELETE_COMPONENT, DUPLICATE_COMPONENT} from '../../actions';

const INITIAL_STATE = {
  components: [],
  component: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_COMPONENTS:
      return { ...state, components: action.payload };
    case SET_COMPONENT:
      return { ...state, component: action.payload };
    case UPDATE_COMPONENT:
      return { ...state, component: action.payload };
    case DELETE_COMPONENT:{
      const res = state.components.filter((item) => item.id !== action.payload);
      return { ...state, components: res };
    }
    case DUPLICATE_COMPONENT: {
      return { ...state, components: [...state.components, action.payload] };
    }
    default: return state;
  }
};
