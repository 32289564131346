/* eslint-disable default-param-last */
import { DELETE_MEMBER, GET_USER_DETAILS } from '../../actions';

const INIT_STATE = {
  fieldsData: [],
  getFieldIds: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
  case DELETE_MEMBER:
    return {
      ...state,
      fieldsData: state.fieldsData.filter((ele) => ele.id !== action.payload),
    };
  case GET_USER_DETAILS:
    return {
      fieldsData: action.payload,
      getFieldIds: action.getFieldIds,
    };
  default: return state;
  }
};
