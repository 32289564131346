/* eslint-disable default-param-last */
import { HIDE_SIDEBAR } from '../actions';

const INIT_STATE = {
  menuHidden: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
  case HIDE_SIDEBAR:
    return {
      menuHidden: action.payload,
    };
  default: return state;
  }
};
