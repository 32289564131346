/* eslint-disable default-param-last */
import {
  SET_SITE,
  SET_ERROR,
} from '../../../../redux/actions';

const INITIAL_STATE = {
  error: null,
  site: {},
  businessNiches: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_SITE:
      return { ...state, site: action.payload };
    case SET_ERROR:
      return { ...state, error: action.payload };
    default: return state;
  }
};
