/* eslint-disable default-param-last */
const INIT_STATE = {
  text: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
  case 'SET_SEARCH_TEXT':
    return {
      text: action.payload,
    };
  default: return state;
  }
};
