import { SPIN_COUNT_INC, SPIN_COUNT_DEC } from '../../actions';

export const spinCountInc = (payload) => ({
  type: SPIN_COUNT_INC,
  payload,
});

export const spinCountDec = (payload) => ({
  type: SPIN_COUNT_DEC,
  payload,
});
