import DataCenterAPI from '../../api/dataCenter';
import { displaySuccess } from '../../../../Utils';
import {
  ADD_DATABASE_FIELD,
  DEL_DATABASE_FIELD,
  SET_DATABASE_FIELD,
  DELETE_SAVED_SEARCH,
  FETCH_DATABASE_FIELDS,
  UPDATE_DATABASE_FIELD,
  FETCH_SAVED_SEARCHES_DATA,
} from '../actions';

const onSuccessFetchDbFields = (payload, getFieldIds) => ({
  type: FETCH_DATABASE_FIELDS,
  payload,
  getFieldIds,
});

export const actionType = (payload, type) => ({
  type,
  payload,
});

/* Database Fields */
export const getDatabaseFields = (instanceId) => async (dispatch) => {
  try {
    const response = await DataCenterAPI.databaseFieldListing(instanceId);
    const { data } = response.data;
    const getFieldIds = data.map((items) => ({ key: items.id, value: [] }));
    dispatch(onSuccessFetchDbFields(data, getFieldIds));
  } catch (err) {
    dispatch(onSuccessFetchDbFields([], []));
  }
};

export const getDatabaseFieldById = (params) => async (dispatch) => {
  try {
    const response = await DataCenterAPI.databaseFieldGetByFieldId(params);
    const { data: { attributes } } = response.data;
    dispatch(actionType(attributes, SET_DATABASE_FIELD));
  } catch (err) {
    dispatch(actionType({}, SET_DATABASE_FIELD));
  }
};

export const databaseFieldUpdate = (params) => async (dispatch) => {
  try {
    const response = await DataCenterAPI.databaseFieldUpdate(params);
    const { data: { attributes } } = response.data;
    dispatch(actionType(attributes, UPDATE_DATABASE_FIELD));
  } catch (err) {
    dispatch(actionType({}, UPDATE_DATABASE_FIELD));
  }
};

export const databaseFieldCreate = (params) => async (dispatch) => {
  try {
    const response = await DataCenterAPI.databaseFieldCreate(params);
    const { data } = response.data;
    dispatch(actionType(data, ADD_DATABASE_FIELD));
  } catch (err) {
    dispatch(actionType(null, ADD_DATABASE_FIELD));
  }
};

export const databaseFieldDelete = (params) => async (dispatch) => {
  try {
    await DataCenterAPI.databaseFieldDelete(params);
    dispatch(actionType(params.field_id, DEL_DATABASE_FIELD));
  } catch (err) {
    dispatch(actionType(null, DEL_DATABASE_FIELD));
  }
};

/* Saved Searches */
export const savedSearchesGet = (instanceId) => async (dispatch) => {
  const params = { instance_id: instanceId };
  const response = await DataCenterAPI.saveSearchesGet(params);
  if (undefined !== response) {
    const { data } = response.data;
    const getOptions = data.map((option) => ({ label: option.name, value: option.id }));
    dispatch(actionType(getOptions, FETCH_SAVED_SEARCHES_DATA));
  }
};

export const savedSearchDelete = (id, optionData) => async (dispatch) => {
  const response = await DataCenterAPI.savedSearchDelete(id, optionData);
  if (undefined !== response) {
    const data = optionData.filter((option) => option.value !== id);
    const { messages } = response.data;
    dispatch(actionType(data, DELETE_SAVED_SEARCH));
    displaySuccess(messages, DELETE_SAVED_SEARCH);
  }
};

export const dataBaseFieldPositionUpdate = (params) => async () => {
  const response = await DataCenterAPI.databaseFieldPositionUpdate(params);
  if (undefined !== response) {
    displaySuccess('Position updated successfully.');
  }
};
