import { combineReducers } from 'redux';
import spinner from './spinner/reducer';
import sidebarReducer from './sideBar/reducer';
import xeroReducer from './settings/xero/reducer';
import searchTextReducer from './searchText/reducer';
import { instanceSlice } from '../api/apiSlice';

const reducers = combineReducers({
  spinner,
  xeroReducer,
  instanceReducer: instanceSlice.reducer,
  sidebarReducer,
  searchTextReducer,
});

export default reducers;
