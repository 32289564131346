/* eslint-disable default-param-last */
import {
  ADD_DATABASE_FIELD,
  DEL_DATABASE_FIELD,
  SET_DATABASE_FIELD,
  SET_DATABASE_FIELDS,
  DELETE_SAVED_SEARCH,
  UPDATE_DATABASE_FIELD,
  FETCH_DATABASE_FIELDS,
  FETCH_SAVED_SEARCHES_DATA,
} from '../actions';

const INIT_STATE = {
  fieldsData: [],
  getFieldIds: [],
  databaseFieldObj: {},
  savedSearchesData: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DATABASE_FIELDS:
      return {
        ...state,
        fieldsData: action.payload,
      };
    case SET_DATABASE_FIELD: case UPDATE_DATABASE_FIELD:
      return {
        ...state,
        databaseFieldObj: action.payload,
      };
    case FETCH_DATABASE_FIELDS:

      return {
        ...state,
        fieldsData: action.payload,
        getFieldIds: action.getFieldIds,
      };
    case ADD_DATABASE_FIELD:
      return {
        ...state,
        fieldsData: [...state.fieldsData, action.payload],
      };
    case DEL_DATABASE_FIELD:
      return {
        ...state,
        fieldsData: state.fieldsData.filter((ele) => ele.id !== action.payload),
      };
    case FETCH_SAVED_SEARCHES_DATA:
      return {
        ...state,
        savedSearchesData: action.payload,
      };
    case DELETE_SAVED_SEARCH:
      return {
        ...state,
        savedSearchesData: action.payload,
      };
    default: return state;
  }
};
