import client from './index';

export default {
  refetchPageData: (params) => client.get(`/sites/${params?.site_id}/pages/${params?.page_id}/page_versions/${params?.id}/objects`),
  getStyleSheet: (params) => client.get(`/sites/${params?.site_id}/pages/${params?.page_id}/page_versions/${params?.id}/stylesheet?prefix=${params?.prefix}`, {
    headers: {
      Accept: 'text/css',
    },
  }),
};
