import {
  CHANGE_LOCALE,
} from '../../actions';

const changeLocale = (locale) => {
  localStorage.setItem('currentLanguage', locale);
  return (
    {
      type: CHANGE_LOCALE,
      payload: locale,
    }
  );
};

export default changeLocale;
