import Base from './Base';
;
class SiteBriefing extends Base {
    constructor(props) {
        super(props);
        Object.assign(this, props);
    }
}
;
export default SiteBriefing;
