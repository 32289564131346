import { isArray } from 'lodash-es';
import Base from './Base';

class User extends Base {
  constructor(props) {
    super(props);

    this.id = String(props.id);
  }

  hasModule(module) {
    return this.modules?.includes(module);
  }

  hasFeature(feature) {
    if (this.role?.name === 'System Administrator') {
      return true;
    }

    // feature can be an array, if it is then we render the component if the user has ANY of the requested features enabled.
    if (isArray(feature) && feature.some((f) => this.hasFeature(f))) {
      return true;
    }

    if (this.feature_overrides?.includes(feature)) {
      return true;
    }

    return this.role?.features?.includes(feature);
  }
}

export default User;
