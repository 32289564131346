/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */
/* eslint-disable default-param-last */

const INIT_STATE = {
  spinCount: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
  case 'SPIN_COUNT_INC':
    return {
      ...state,
      spinCount: state.spinCount + 1,
    };
  case 'SPIN_COUNT_DEC':
    return {
      ...state,
      spinCount: state.spinCount - 1,
    };
  default: return state;
  }
};
