var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createSlice } from '@reduxjs/toolkit';
import { get, some, isArray } from 'lodash-es';
import { createStore } from '../../../rootStore';
import { spinCountInc, spinCountDec } from '../redux/spinner/actions';
import PageVersionAPI from '../../../api/page_version';
import User from '../models/User';
import UserRecentItem from '../models/UserRecentItem';
import { getCookie } from '../../../Utils';
// A general-purpose function to use whenever we want optimistic updating.
const onQueryStarted = (queryPath_1, queryObject_1, patchedObject_1, _a) => __awaiter(void 0, [queryPath_1, queryObject_1, patchedObject_1, _a], void 0, function* (queryPath, queryObject, patchedObject, { dispatch, queryFulfilled }) {
    const patchResult = dispatch(apiSlice.util.updateQueryData(queryPath, queryObject, (objects) => {
        if (!isArray(objects)) {
            return;
        }
        const cachedObject = objects.find((d) => d.id === patchedObject.id);
        if (cachedObject) {
            Object.assign(cachedObject, patchedObject);
        }
    }));
    try {
        yield queryFulfilled;
    }
    catch (e) {
        patchResult.undo();
    }
});
const baseQueryWithSpinner = (args, api, extraOptions) => __awaiter(void 0, void 0, void 0, function* () {
    const store = createStore();
    const { pageData: { admin_domain } } = store.getState();
    const adminDomain = admin_domain ? `https://${admin_domain}` : '';
    // We have a tricky situation with the spinner... we want to be able to disable the spinner for some operations. Usually that's easy, we just pass disableSpinner in the args. But if a mutation triggers a cache invalidation then there is no way to disable the spinner on the resulting GET request. We are currently looking at the "forced" variable, because this is always true for GET requests that result from a cache invalidation. We are not currently using "forceRefetch", but if we ever do then these requests will always have the spinner disabled, and we will need to re-think how we do this.
    if (get(args, 'disableSpinner') !== true && api.forced !== true) {
        store.dispatch(spinCountInc());
    }
    // The X-User-Token is actually only used by the logout route, but there's no harm in adding it to all requests.
    const token = getCookie('USER_TOKEN');
    const headers = {};
    if (token) {
        headers['X-User-Token'] = token;
    }
    const result = yield fetchBaseQuery({
        baseUrl: `${adminDomain}/api/v1`,
        credentials: 'include',
        headers,
    })(args, api, extraOptions);
    if (get(args, 'disableSpinner') !== true && api.forced !== true) {
        store.dispatch(spinCountDec());
    }
    const resultData = result.data;
    if (extraOptions === null || extraOptions === void 0 ? void 0 : extraOptions.modelClass) {
        if (isArray(resultData === null || resultData === void 0 ? void 0 : resultData.data)) {
            return {
                data: resultData.data.map((d) => extraOptions.modelClass ? new extraOptions.modelClass(d) : d),
                meta: resultData,
            };
        }
        return {
            data: { data: resultData ? new extraOptions.modelClass(resultData === null || resultData === void 0 ? void 0 : resultData.data) : null },
            meta: resultData,
        };
    }
    if (result.error) {
        return result;
    }
    return {
        data: resultData === null || resultData === void 0 ? void 0 : resultData.data,
        meta: resultData,
    };
});
// This function can be added to any endpoint to add the meta key to the response. The downside of this is that we need
// to add one more nesting level to the response data. So without the function it looks like this:
//
// const thing = { data: thing} = useGetThingQuery();
//
// but with the function you need to do this:
//
// const thing = { data: { data: thing } = {} } = useGetThingQuery();
//
// But we can get the response data like this:
//
// const thing = { data: { data: thing } = {}, meta } = useGetThingQuery();
// meta contains the is_success and messages keys from the API response.
//
// If anyone can figure out how to do this without needing the extra nesting gets a prize.
function transformResponse(baseQueryReturnValue, meta) {
    return { data: baseQueryReturnValue.data, meta };
}
export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithSpinner,
    tagTypes: [
        'instance',
        'site',
        'domain',
        'text_style',
        'button_style',
        'briefing',
        'sector',
        'section',
        'section_html',
        'tooltip',
        'page',
        'page_list',
        'orphan_page',
        'master_page',
        'page_version',
        'template',
        'url',
        'custom_code',
        'database_field',
        'component_category',
        'page_data',
        'role',
        'user',
        'recent_item',
        'cta',
        'email_series',
    ],
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        // Generic object endpoint. Don't want to cache these at the moment because they may interfere with the
        // non-generic endpoints.
        getObjectById: builder.query({
            query: ({ type, id }) => `/objects/${type}/${id}`,
        }),
        // Instances
        getInstances: builder.query({
            query: () => '/instances?order_by=company_name&order_direction=asc',
            providesTags: ['instance'],
        }),
        getInstanceById: builder.query({
            query: (instanceId) => `/instances/${instanceId}`,
            providesTags: ['instance'],
        }),
        updateInstance: builder.mutation({
            query: (params) => ({
                url: `/instances/${params.id}`,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: ['instance'],
        }),
        getMasterInstances: builder.query({
            query: () => '/instances/master_instances',
            providesTags: ['instance'],
        }),
        importInstanceFromLegacy: builder.mutation({
            query: ({ instanceId, siteId }) => ({
                url: `/instances/${instanceId}/import_instance?site_id=${siteId}`,
                method: 'POST',
            }),
            invalidatesTags: ['instance'],
        }),
        getInstanceData: builder.query({
            query: (instanceId) => `/instances/${instanceId}/instance_data`,
        }),
        duplicateInstance: builder.mutation({
            query: (params) => ({
                url: `/instances/${params.id}/duplicate`,
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['instance'],
        }),
        // Sites
        getSiteById: builder.query({
            query: (siteId) => `/sites/${siteId}`,
            providesTags: ['site'],
        }),
        getSites: builder.query({
            query: (instanceId) => `/sites?instance_id=${instanceId}`,
            providesTags: ['site'],
            transformResponse: (data) => {
                data === null || data === void 0 ? void 0 : data.sort((o1, o2) => {
                    // We want to put these at the bottom of the list: sites without domains, ACE sites, old mobile (m.) sites, and .wb.gs sites.
                    const bottoms = [
                        /^$/,
                        /^ace-/,
                        /\.ace-/,
                        /^m\./,
                        /\.wb\.gs/,
                    ];
                    if (typeof o1.label !== 'string') {
                        o1.label = '';
                    }
                    if (typeof o2.label !== 'string') {
                        o2.label = '';
                    }
                    const anyMatch1 = some(bottoms, (b) => o1.label.match(b));
                    const anyMatch2 = some(bottoms, (b) => o2.label.match(b));
                    if (anyMatch1 && anyMatch2) {
                        return 0;
                    }
                    if (anyMatch1) {
                        return 1;
                    }
                    if (anyMatch2) {
                        return -1;
                    }
                    return o1.label > o2.label ? 1 : -1;
                });
                return data;
            },
        }),
        getAllSites: builder.query({
            query: () => ({
                url: `/sites`,
                method: 'GET',
            }),
        }),
        createSite: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload}/sites`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['site'],
        }),
        deleteSite: builder.mutation({
            query: ({ instance_id, id }) => ({
                url: `/instances/${instance_id}/sites/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['site'],
        }),
        updateSite: builder.mutation({
            query: (params) => ({
                url: `/instances/${params.instance_id}/sites/${params.id}`,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: ['site'],
        }),
        sitesLinkingToMe: builder.query({
            query: ({ instanceId, siteId }) => ({
                url: `/instances/${instanceId}/sites/${siteId}/linking_to_me`,
                method: 'GET',
            }),
        }),
        // Domains
        getDomains: builder.query({
            query: (siteId) => `/sites/${siteId}/domains`,
            providesTags: ['domain'],
        }),
        getAllDomains: builder.query({
            query: ({ domainName, disableSpinner }) => ({
                url: `/domains?domain_name=${domainName}`,
                method: 'GET',
                disableSpinner,
            }),
            providesTags: ['domain'],
        }),
        addNewDomain: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/domains`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['domain'],
        }),
        updateDomain: builder.mutation({
            query: (domain) => ({
                url: `/sites/${domain.site_id}/domains/${domain.id}`,
                method: 'PATCH',
                body: domain,
            }),
            onQueryStarted: (patchedObject, ...rest) => __awaiter(void 0, void 0, void 0, function* () { return onQueryStarted('getDomains', patchedObject.site_id, patchedObject, rest[0]); }),
            invalidatesTags: ['domain', 'site'], // Adding 'site' so that the site selector updates when changing domains
        }),
        deleteDomain: builder.mutation({
            query: ({ site_id, id }) => ({
                url: `/sites/${site_id}/domains/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['domain'],
        }),
        makeDomainPrimary: builder.mutation({
            query: (domain) => ({
                url: `/sites/${domain.site_id}/domains/${domain.id}/make_primary`,
                method: 'POST',
            }),
            invalidatesTags: ['domain', 'site'], // Adding 'site' so that the site selector updates when changing domains
        }),
        issueDomainCertificate: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/domains/${payload.id}/issue_certificate`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['domain'],
            onQueryStarted(patchedDomain_1, _a) {
                return __awaiter(this, arguments, void 0, function* (patchedDomain, { dispatch, queryFulfilled }) {
                    const patchResult = dispatch(apiSlice.util.updateQueryData('getDomains', patchedDomain.site_id, (domains) => {
                        const domain = domains.find((d) => d.id === patchedDomain.id);
                        domain.issuing = true;
                        if (domain) {
                            Object.assign(domain, patchedDomain);
                        }
                    }));
                    try {
                        yield queryFulfilled;
                    }
                    catch (e) {
                        patchResult.undo();
                    }
                });
            },
        }),
        // Pages
        getPages: builder.query({
            query: ({ instanceId, siteId }) => `/sites/${siteId}/pages?instance_id=${instanceId}`,
            providesTags: ['page_list'],
        }),
        getPage: builder.query({
            query: ({ id, instanceId, siteId }) => `/sites/${siteId}/pages/${id}?instance_id=${instanceId}`,
            providesTags: ['page'],
        }),
        getOrphanPages: builder.query({
            query: ({ instanceId, siteId }) => `/sites/${siteId}/orphan_pages?instance_id=${instanceId}`,
            providesTags: ['orphan_page'],
        }),
        getMasterPages: builder.query({
            query: ({ instanceId, siteId }) => `/sites/${siteId}/master_pages?instance_id=${instanceId}`,
            providesTags: ['master_page'],
        }),
        getPageSections: builder.query({
            query: ({ instanceId, siteId, pageId }) => `/sites/${siteId}/pages/${pageId}/sections?instance_id=${instanceId}`,
        }),
        addNewPage: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/pages`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: (result, error, args) => {
                if ((args === null || args === void 0 ? void 0 : args.type) === 'master') {
                    return ['master_page'];
                }
                if ((args === null || args === void 0 ? void 0 : args.type) === 'normal' && !(args === null || args === void 0 ? void 0 : args.parent_id)) {
                    return ['orphan_page'];
                }
                return ['page_list'];
            },
        }),
        deletePage: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/pages/${payload.current_page}?instance_id=${payload.instance_id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, args) => {
                if ((args === null || args === void 0 ? void 0 : args.type) === 'master') {
                    return ['master_page'];
                }
                if ((args === null || args === void 0 ? void 0 : args.type) === 'normal' && !(args === null || args === void 0 ? void 0 : args.parent_id)) {
                    return ['orphan_page'];
                }
                return ['page_list'];
            },
        }),
        pageUpdate: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/pages/${payload === null || payload === void 0 ? void 0 : payload.page_id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['page_list', 'page_data', 'section'],
        }),
        duplicatePage: builder.mutation({
            query: (params) => ({
                url: `/pages/${params.id}/duplicate`,
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['page_list'],
        }),
        getPageTree: builder.query({
            query: ({ instanceId, siteId }) => `/instances/${instanceId}/sites/${siteId}/page_tree`,
        }),
        // Email Pages
        getEmails: builder.query({
            query: ({ instanceId }) => `/instances/${instanceId}/email_pages`,
            providesTags: ['page_list'],
        }),
        emailPageUpdate: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload.instance_id}/email_pages/${payload === null || payload === void 0 ? void 0 : payload.page_id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['page_list', 'page_data', 'section'],
        }),
        emailPageDuplicate: builder.mutation({
            query: (params) => ({
                url: `/instances/${params.instance_id}/email_pages/${params.id}/duplicate`,
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['page_list'],
        }),
        emailPageCreate: builder.mutation({
            query: (params) => ({
                url: `/instances/${params.instance_id}/email_pages`,
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['page_list'],
        }),
        emailPageDelete: builder.mutation({
            query: (params) => ({
                url: `/instances/${params.instance_id}/email_pages/${params.id}`,
                method: 'DELETE',
                body: params,
            }),
            invalidatesTags: ['page_list'],
        }),
        emailPageProof: builder.mutation({
            query: (params) => ({
                url: `/instances/${params.instance_id}/email_pages/${params.id}/email_proof`,
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['page_list'],
        }),
        // Page Versions
        getPageVersions: builder.query({
            query: (_a) => {
                var { pageId, siteId } = _a, payload = __rest(_a, ["pageId", "siteId"]);
                return `/sites/${siteId}/pages/${pageId}/page_versions?${new URLSearchParams(payload)}`;
            },
            providesTags: ['page_version'],
        }),
        getPageVersion: builder.query({
            query: ({ id, pageId, instanceId, siteId, }) => `/sites/${siteId}/pages/${pageId}/page_versions/${id}?instance_id=${instanceId}`,
            providesTags: ['page_version'],
        }),
        getEmailPageVersions: builder.query({
            query: ({ pageId, instanceId }) => `/instances/${instanceId}/email_pages/${pageId}/page_versions`,
            providesTags: ['page_version'],
        }),
        pageVersionCreate: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/pages/${payload.page_id}/page_versions/`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['page_version'],
        }),
        pageVersionDelete: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.siteId}/pages/${payload.pageId}/page_versions/${payload.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['page_version'],
        }),
        pageVersionPublish: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/pages/${payload.page_id}/page_versions/${payload.id}/change_publish_draft`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['page_version'],
        }),
        pageVersionUpdate: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload === null || payload === void 0 ? void 0 : payload.site_id}/pages/${payload === null || payload === void 0 ? void 0 : payload.page_id}/page_versions/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['page_version', 'page_data', 'section'],
        }),
        // URLs
        getURLById: builder.query({
            query: (id) => `/urls/${id}`,
            providesTags: ['url'],
        }),
        // Sectors
        getAllSectorsFromSite: builder.query({
            query: ({ instanceId, siteId }) => `/instances/${instanceId}/sites/${siteId}/sectors`,
            providesTags: ['sector'],
        }),
        getSectors: builder.query({
            query: (params) => `/sites/${params === null || params === void 0 ? void 0 : params.siteId}/pages/${params === null || params === void 0 ? void 0 : params.pageId}/page_versions/${params === null || params === void 0 ? void 0 : params.pageVersionId}/sectors`,
            providesTags: ['sector'],
        }),
        // Sections
        getSections: builder.query({
            query: (params) => `/sites/${params === null || params === void 0 ? void 0 : params.siteId}/pages/${params === null || params === void 0 ? void 0 : params.pageId}/page_versions/${params === null || params === void 0 ? void 0 : params.pageVersionId}/sections${(params === null || params === void 0 ? void 0 : params.includeContent) ? '?include_content=true' : ''}`,
            providesTags: (result) => (result
                ? [
                    ...result.map(({ id }) => ({ type: 'section', id })),
                    { type: 'section', id: 'LIST' },
                ]
                : [{ type: 'section', id: 'LIST' }]),
        }),
        getMasterSections: builder.query({
            query: (params) => `/sites/${params === null || params === void 0 ? void 0 : params.siteId}/pages/${params === null || params === void 0 ? void 0 : params.pageId}/page_versions/${params === null || params === void 0 ? void 0 : params.pageVersionId}/master_sections`,
            providesTags: (result) => (result
                ? [
                    ...result.map(({ id }) => ({ type: 'section', id })),
                    { type: 'section', id: 'LIST' },
                ]
                : [{ type: 'section', id: 'LIST' }]),
        }),
        getSectionHTML: builder.query({
            query: (params) => `/instances/${params === null || params === void 0 ? void 0 : params.instanceId}/sections/${params === null || params === void 0 ? void 0 : params.sectionId}/html`,
            providesTags: (result, error, args) => [{ type: 'section_html', id: args.sectionId }],
        }),
        sectionCreate: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload.instance_id}/sections`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['page_data', { type: 'section', id: 'LIST' }],
        }),
        getSection: builder.query({
            query: (params) => `/instances/${params === null || params === void 0 ? void 0 : params.instanceId}/sections/${params === null || params === void 0 ? void 0 : params.sectionId}`,
            providesTags: (result, error, args) => [{ type: 'section', id: args.sectionId }],
        }),
        sectionUpdate: builder.mutation({
            query: (_a) => {
                var { instance_id, id } = _a, payload = __rest(_a, ["instance_id", "id"]);
                return ({
                    url: `/instances/${instance_id}/sections/${id}`,
                    method: 'PUT',
                    body: payload,
                });
            },
            invalidatesTags: (result, error, args) => {
                var _a, _b;
                return ([
                    ...['page_data', { type: 'section', id: args.id }, { type: 'section_html', id: args.id }],
                    ...(((_a = args === null || args === void 0 ? void 0 : args.column_section) === null || _a === void 0 ? void 0 : _a.id) ? [{ type: 'section', id: args === null || args === void 0 ? void 0 : args.column_section.id }] : []),
                    ...(((_b = args === null || args === void 0 ? void 0 : args.column_section) === null || _b === void 0 ? void 0 : _b.id) ? [{ type: 'section_html', id: args === null || args === void 0 ? void 0 : args.column_section.id }] : []),
                    ...((args === null || args === void 0 ? void 0 : args.column_attributes) ? args.column_attributes.map((c) => ({ type: 'section', id: c.id })) : []),
                    ...((args === null || args === void 0 ? void 0 : args.column_attributes) ? args.column_attributes.map((c) => ({ type: 'section_html', id: c.id })) : []),
                ]);
            },
        }),
        sectionDelete: builder.mutation({
            query: ({ instance_id, id }) => ({
                url: `/instances/${instance_id}/sections/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['page_data', { type: 'section', id: 'LIST' }],
        }),
        getColumns: builder.query({
            query: ({ instanceId, sectionId }) => `/instances/${instanceId}/sections/${sectionId}/columns`,
            providesTags: (result) => (result
                ? [
                    ...result.map(({ id }) => ({ type: 'section', id })),
                    { type: 'section', id: 'LIST' },
                ]
                : [{ type: 'section', id: 'LIST' }]),
        }),
        sectionPositionUpdate: builder.mutation({
            query: (_a) => {
                var { instance_id } = _a, payload = __rest(_a, ["instance_id"]);
                return ({
                    url: `/instances/${instance_id}/sections/update_position`,
                    method: 'PUT',
                    body: payload,
                });
            },
            invalidatesTags: ['page_data', { type: 'section', id: 'LIST' }],
        }),
        getSectionsByType: builder.query({
            query: ({ instanceId, siteId, type, version, }) => `/instances/${instanceId}/sites/${siteId}/sections?type=${type}&version=${version}`,
        }),
        duplicateSection: builder.mutation({
            query: (_a) => {
                var { instanceId } = _a, params = __rest(_a, ["instanceId"]);
                return ({
                    url: `/instances/${instanceId}/sections/duplicate`,
                    method: 'POST',
                    body: params,
                });
            },
            invalidatesTags: [{ type: 'section', id: 'LIST' }],
        }),
        // Text Styles
        getTextStyles: builder.query({
            query: (siteId) => `/sites/${siteId}/text_styles`,
            providesTags: ['text_style'],
        }),
        getTextStyleById: builder.query({
            query: (params) => `/sites/${params === null || params === void 0 ? void 0 : params.site_id}/text_styles/${params === null || params === void 0 ? void 0 : params.id}`,
            providesTags: ['text_style'],
        }),
        addNewTextStyle: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/text_styles`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['text_style'],
        }),
        updateTextStyle: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload === null || payload === void 0 ? void 0 : payload.site_id}/text_styles/${payload === null || payload === void 0 ? void 0 : payload.id}`,
                method: 'PATCH',
                body: payload,
            }),
            invalidatesTags: ['text_style'],
        }),
        deleteTextStyle: builder.mutation({
            query: (params) => ({
                url: `/sites/${params === null || params === void 0 ? void 0 : params.site_id}/text_styles/${params === null || params === void 0 ? void 0 : params.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['text_style'],
        }),
        // Custom Fonts
        getCustomFonts: builder.query({
            query: () => '/custom_fonts',
        }),
        // Load Media Tool
        getMediaFiles: builder.query({
            query: (instanceId) => `/instances/${instanceId}/load_media_tool`,
        }),
        // Button Styles
        getButtonStyles: builder.query({
            query: (siteId) => `/sites/${siteId}/button_styles`,
            providesTags: ['button_style'],
        }),
        getButtonStyleById: builder.query({
            query: (params) => `/sites/${params === null || params === void 0 ? void 0 : params.site_id}/button_styles/${params === null || params === void 0 ? void 0 : params.id}`,
            providesTags: ['button_style'],
        }),
        addButtonStyle: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/button_styles`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['button_style'],
        }),
        updateButtonStyle: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload === null || payload === void 0 ? void 0 : payload.site_id}/button_styles/${payload === null || payload === void 0 ? void 0 : payload.id}`,
                method: 'PATCH',
                body: payload,
            }),
            invalidatesTags: ['button_style'],
        }),
        deleteButtonStyle: builder.mutation({
            query: (params) => ({
                url: `/sites/${params === null || params === void 0 ? void 0 : params.site_id}/button_styles/${params === null || params === void 0 ? void 0 : params.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['button_style'],
        }),
        // Member email logs
        getMemberEmailLogs: builder.query({
            query: (params) => `/instances/${params === null || params === void 0 ? void 0 : params.instance_id}/members/${params === null || params === void 0 ? void 0 : params.member_id}/email_logs`,
        }),
        // Export Members
        exportMembers: builder.query({
            query: (params) => `/searches/${params === null || params === void 0 ? void 0 : params.id}/export_members?export_type=${params === null || params === void 0 ? void 0 : params.type}&instance_id=${params === null || params === void 0 ? void 0 : params.instance_id}`,
        }),
        // Site Briefing
        createSiteBriefing: builder.mutation({
            query: () => ({
                url: '/briefings',
                method: 'POST',
            }),
            invalidatesTags: ['briefing'],
        }),
        getSiteBriefings: builder.query({
            query: (siteId) => `/briefings${siteId ? `?site_id=${siteId}` : ''}`,
            providesTags: (result) => (result
                ? [
                    ...result.map(({ id }) => ({ type: 'briefing', id })),
                    { type: 'briefing', id: 'LIST' },
                ]
                : [{ type: 'briefing', id: 'LIST' }]),
        }),
        getSiteBriefing: builder.query({
            query: (id) => `/briefings/${id}`,
            providesTags: (result, error, args) => [{ type: 'briefing', id: args.id }],
        }),
        deleteSiteBriefing: builder.mutation({
            query: (briefing) => ({
                url: `/briefings/${briefing.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['briefing'],
        }),
        updateSiteBriefing: builder.mutation({
            query: (_a) => {
                var { disableSpinner } = _a, briefing = __rest(_a, ["disableSpinner"]);
                return ({
                    url: `/briefings/${briefing.id}`,
                    method: 'PATCH',
                    body: briefing,
                    disableSpinner,
                });
            },
            onQueryStarted(patch, { dispatch, queryFulfilled }) {
                dispatch(apiSlice.util.updateQueryData('getSiteBriefing', patch.id, (briefing) => Object.assign(briefing, patch)));
                dispatch(apiSlice.util.invalidateTags([{ type: 'briefing', id: 'LIST' }]));
                queryFulfilled.catch(() => dispatch(apiSlice.util.invalidateTags(['briefing'])));
            },
        }),
        addImageToBriefing: builder.mutation({
            query: ({ disableSpinner, briefing, name, file, }) => {
                const body = new FormData();
                body.append('name', name);
                body.append('file', file);
                return {
                    url: `/briefings/${briefing.id}/image`,
                    method: 'POST',
                    body,
                    disableSpinner,
                };
            },
            invalidatesTags: ['briefing'],
        }),
        generateInstanceFromSiteBriefing: builder.mutation({
            query: (briefing) => ({
                url: `/briefings/${briefing.id}/generate_instance`,
                method: 'POST',
            }),
            invalidatesTags: ['briefing'],
        }),
        generateQuestions: builder.query({
            query: ({ briefingId, productPageId, subPageId }) => `/briefings/${briefingId}/questions?product_page_id=${productPageId}&sub_page_id=${subPageId}`,
        }),
        // Misc
        getSpelling: builder.query({
            query: () => '/spelling',
        }),
        // Link Tool
        loadLinkTool: builder.query({
            query: (id) => `/instances/${id}/load_link_tool`,
        }),
        // Reports
        getReportConfig: builder.query({
            query: () => '/reports/config',
        }),
        getReportData: builder.query({
            query: (config) => ({
                url: '/reports/data',
                method: 'POST',
                body: config,
            }),
        }),
        // Templates
        getTemplates: builder.query({
            query: (params) => `/sites/${params === null || params === void 0 ? void 0 : params.siteId}/templates?instance_id=${params === null || params === void 0 ? void 0 : params.instanceId}`,
            providesTags: (result) => [
                'template',
                ...result.map(({ id }) => ({ type: 'template', id })),
            ],
        }),
        getTemplate: builder.query({
            query: (params) => `/sites/${params === null || params === void 0 ? void 0 : params.siteId}/templates/${params === null || params === void 0 ? void 0 : params.id}?instance_id=${params === null || params === void 0 ? void 0 : params.instanceId}`,
            providesTags: (result, error, args) => [{ type: 'template', id: args.id }],
        }),
        // Custom Tooltips
        getCustomTooltips: builder.query({
            query: () => '/custom_tooltips',
            providesTags: ['tooltip'],
        }),
        createCustomTooltip: builder.mutation({
            query: (payload) => ({
                url: '/custom_tooltips',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['tooltip'],
        }),
        updateCustomTooltip: builder.mutation({
            query: (payload) => ({
                url: `/custom_tooltips/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['tooltip'],
        }),
        // Email Series
        getEmailSeries: builder.query({
            query: (instanceId) => `/instances/${instanceId}/email_series`,
            providesTags: (result) => [
                'email_series',
                ...result.map(({ id }) => ({ type: 'email_series', id })),
            ],
        }),
        getEmailSeriesById: builder.query({
            query: (payload) => `/instances/${payload.instance_id}/email_series/${payload.id}`,
            providesTags: (result, error, args) => [{ type: 'email_series', id: args.id }],
        }),
        createEmailSeries: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload.instance_id}/email_series`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['email_series'],
        }),
        deleteEmailSeries: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload.instance_id}/email_series/${payload.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'email_series', id }],
            transformResponse,
        }),
        updateEmailSeries: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload.instance_id}/email_series/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'email_series', id }],
            transformResponse,
        }),
        emailSeriesSubscribeMember: builder.mutation({
            query: (payload) => ({
                url: `instances/${payload.instance_id}/searches/${payload.id}/subscribe_search_members_to_email_series`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['email_series'],
        }),
        // Custom Codes
        getCustomCodes: builder.query({
            query: (siteId) => `/sites/${siteId}/custom_codes`,
            providesTags: ['custom_code'],
        }),
        getCustomCode: builder.query({
            query: (params) => `/sites/${params === null || params === void 0 ? void 0 : params.site_id}/custom_codes/${params === null || params === void 0 ? void 0 : params.id}`,
        }),
        createCustomCode: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload === null || payload === void 0 ? void 0 : payload.site_id}/custom_codes`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['custom_code'],
        }),
        deleteCustomCode: builder.mutation({
            query: (params) => ({
                url: `/sites/${params === null || params === void 0 ? void 0 : params.siteId}/custom_codes/${params === null || params === void 0 ? void 0 : params.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['custom_code'],
        }),
        updateCustomCode: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload === null || payload === void 0 ? void 0 : payload.site_id}/custom_codes/${payload === null || payload === void 0 ? void 0 : payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['custom_code'],
        }),
        // Database Fields
        getDatabaseFields: builder.query({
            query: (params) => `/instances/${params === null || params === void 0 ? void 0 : params.instanceId}/database_fields/field_search`,
            providesTags: ['database_field'],
        }),
        // Component Categories
        getComponentCategories: builder.query({
            query: () => '/component_categories',
            providesTags: ['component_category'],
        }),
        // Page Data
        getPageData: builder.query({
            query: (params) => `sites/${params === null || params === void 0 ? void 0 : params.siteId}/pages/${params === null || params === void 0 ? void 0 : params.pageId}/page_versions/${params === null || params === void 0 ? void 0 : params.pageVersionId}/objects`,
            providesTags: ['page_data'],
        }),
        // Admin Roles
        getRoles: builder.query({
            query: () => '/roles',
            providesTags: ['role'],
        }),
        // Users
        getUser: builder.query({
            query: (id) => `/users/${id}`,
            providesTags: ['user'],
            extraOptions: { modelClass: User },
            transformResponse,
        }),
        getUsers: builder.query({
            query: () => '/users',
            providesTags: ['user'],
            extraOptions: { modelClass: User },
        }),
        createUser: builder.mutation({
            query: (params) => ({
                url: '/users',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['user'],
            extraOptions: { modelClass: User },
            transformResponse,
        }),
        updateUser: builder.mutation({
            query: (params) => ({
                url: `/users/${params.id}`,
                method: 'PUT',
                body: params,
            }),
            invalidatesTags: ['user'],
            transformResponse,
        }),
        deleteUser: builder.mutation({
            query: (params) => ({
                url: `/users/${params.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['user'],
            transformResponse,
        }),
        // Current User Profile
        loginUserEmail: builder.mutation({
            query: (params) => ({
                url: '/login',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['user'],
        }),
        loginUserGoogle: builder.mutation({
            query: (params) => ({
                url: '/google_login',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['user'],
        }),
        getUserProfile: builder.query({
            query: () => '/profile',
            providesTags: ['user'],
            transformResponse,
            // This is only used to figure out if the user is logged into the dashboard. It returns a 404 if they are not,
            // but we don't want to display an error message, so we ignore the 404.
            // TODO: find another way to do this
            transformErrorResponse: () => null,
            extraOptions: { modelClass: User },
        }),
        logoutUser: builder.mutation({
            query: (params) => ({
                url: '/logout',
                method: 'DELETE',
                body: params,
            }),
            invalidatesTags: ['user'],
        }),
        // TextBlock
        getTextBlockHistory: builder.query({
            query: (id) => `/history_trackers/${id}`,
        }),
        // Geographic Area
        getGeographicArea: builder.query({
            query: () => `/geo_areas`,
        }),
        // Business Niches
        getBusinessNiches: builder.query({
            query: () => `/business_niches`,
        }),
        // Geographic Code
        getGeographicCode: builder.query({
            query: (params) => `/geocode/?address=${params.address}`,
        }),
        // Recent Items
        getRecentItems: builder.query({
            query: (params) => ({
                url: `/users/${params.userId}/recent_items?instance_id=${params.instanceId}`,
                method: 'GET',
                disableSpinner: true,
            }),
            extraOptions: { modelClass: UserRecentItem },
            providesTags: ['recent_item'],
        }),
        updateRecentItems: builder.mutation({
            query: (params) => ({
                url: `/users/${params.userId}/recent_items`,
                method: 'POST',
                body: { items: params.items },
                disableSpinner: true,
            }),
            invalidatesTags: ['recent_item'],
        }),
        // CTAs
        getChatTranscript: builder.query({
            query: (params) => `/instances/${params.instanceId}/cta_logs/${params.id}/transcript`,
            providesTags: ['cta'],
        }),
    }),
});
export const { endpoints, 
// Objects
useGetObjectByIdQuery, 
// Instances
useGetInstancesQuery, useGetInstanceByIdQuery, useUpdateInstanceMutation, useGetMasterInstancesQuery, useImportInstanceFromLegacyMutation, useGetInstanceDataQuery, useDuplicateInstanceMutation, 
// Sites
useGetSiteByIdQuery, useGetSitesQuery, useGetAllSitesQuery, useCreateSiteMutation, useDeleteSiteMutation, useUpdateSiteMutation, useSitesLinkingToMeQuery, 
// Geographic Area
useGetGeographicAreaQuery, 
// Business Niches
useGetBusinessNichesQuery, 
// Geographic Code
useLazyGetGeographicCodeQuery, 
// Domains
useGetDomainsQuery, useGetAllDomainsQuery, useUpdateDomainMutation, useDeleteDomainMutation, useAddNewDomainMutation, useMakeDomainPrimaryMutation, useIssueDomainCertificateMutation, 
// Pages
useGetPagesQuery, useGetPageQuery, useGetOrphanPagesQuery, useGetMasterPagesQuery, useGetPageSectionsQuery, useAddNewPageMutation, useDeletePageMutation, usePageUpdateMutation, useDuplicatePageMutation, useGetPageTreeQuery, 
// Email Pages
useGetEmailsQuery, useEmailPageUpdateMutation, useEmailPageDuplicateMutation, useEmailPageCreateMutation, useEmailPageDeleteMutation, useEmailPageProofMutation, 
// Page Versions
useGetPageVersionsQuery, useGetPageVersionQuery, usePageVersionPublishMutation, useGetEmailPageVersionsQuery, usePageVersionCreateMutation, usePageVersionDeleteMutation, usePageVersionUpdateMutation, 
// URLs
useGetURLByIdQuery, 
// Sectors
useGetAllSectorsFromSiteQuery, useGetSectorsQuery, 
// Sections
useGetSectionsQuery, useGetMasterSectionsQuery, useGetSectionHTMLQuery, useSectionCreateMutation, useGetSectionQuery, useSectionUpdateMutation, useSectionDeleteMutation, useGetColumnsQuery, useSectionPositionUpdateMutation, useGetSectionsByTypeQuery, useDuplicateSectionMutation, 
// Text Styles
useGetTextStyleByIdQuery, useGetTextStylesQuery, useAddNewTextStyleMutation, useUpdateTextStyleMutation, useDeleteTextStyleMutation, 
// Custom Fonts
useGetCustomFontsQuery, 
// Load Media Tool
useGetMediaFilesQuery, 
// Button Styles
useGetButtonStylesQuery, useAddButtonStyleMutation, useGetButtonStyleByIdQuery, useUpdateButtonStyleMutation, useDeleteButtonStyleMutation, 
// Member Email Logs
useGetMemberEmailLogsQuery, 
// Export Members
useExportMembersQuery, 
// Site Briefing
useCreateSiteBriefingMutation, useGetSiteBriefingsQuery, useGetSiteBriefingQuery, useDeleteSiteBriefingMutation, useUpdateSiteBriefingMutation, useAddImageToBriefingMutation, useGenerateInstanceFromSiteBriefingMutation, useLazyGenerateQuestionsQuery, 
// Misc
useGetSpellingQuery, 
// Link tool
useLoadLinkToolQuery, 
// Reports
useGetReportConfigQuery, useGetReportDataQuery, 
// Templates
useGetTemplatesQuery, useGetTemplateQuery, 
// Custom Tooltips
useGetCustomTooltipsQuery, useCreateCustomTooltipMutation, useUpdateCustomTooltipMutation, 
// Email Series
useGetEmailSeriesQuery, useGetEmailSeriesByIdQuery, useCreateEmailSeriesMutation, useDeleteEmailSeriesMutation, useUpdateEmailSeriesMutation, useEmailSeriesSubscribeMemberMutation, 
// Custom Code
useGetCustomCodesQuery, useGetCustomCodeQuery, useCreateCustomCodeMutation, useUpdateCustomCodeMutation, useDeleteCustomCodeMutation, 
// Database fields
useGetDatabaseFieldsQuery, 
// Component Categories
useGetComponentCategoriesQuery, 
// Page Data
useGetPageDataQuery, 
// Admin Roles
useGetRolesQuery, 
// Users
useGetUserQuery, useGetUsersQuery, useCreateUserMutation, useUpdateUserMutation, useDeleteUserMutation, 
// Current User Profile
useLoginUserEmailMutation, useLoginUserGoogleMutation, useGetUserProfileQuery, useLogoutUserMutation, 
// TextBlock
useGetTextBlockHistoryQuery, 
// Recent Items
useGetRecentItemsQuery, useUpdateRecentItemsMutation, 
// CTAs
useGetChatTranscriptQuery, } = apiSlice;
const pageDataElement = document.getElementById('pageData');
const pageData = pageDataElement ? JSON.parse(pageDataElement.textContent) : {};
export const pageDataSlice = createSlice({
    name: 'page_data',
    initialState: pageData,
    reducers: {
        setPageData: (state, action) => state = Object.assign(Object.assign({}, state), action.payload),
    },
});
export const { setPageData } = pageDataSlice.actions;
const instanceInitialState = {
    sectionsData: [],
    instancesList: [],
    instance: null,
};
export const instanceSlice = createSlice({
    name: 'instance',
    initialState: instanceInitialState,
    reducers: {
        setInstance: (state, action) => state = Object.assign(Object.assign({}, state), { [action.payload.key]: action.payload.data }),
        updateInstance: (state, action) => {
            const objIndex = state.instancesList.findIndex((obj) => obj.id === action.payload.id);
            if (objIndex !== -1) {
                state.instancesList[objIndex] = action.payload.data;
            }
        },
    },
});
export const { setInstance, updateInstance } = instanceSlice.actions;
export const refetchPageData = () => __awaiter(void 0, void 0, void 0, function* () {
    var _b;
    const store = createStore();
    const { pageData: { page, page_version: pageVersion, site } } = store.getState();
    const res = yield PageVersionAPI.refetchPageData({ site_id: site === null || site === void 0 ? void 0 : site.id, page_id: page === null || page === void 0 ? void 0 : page.id, id: pageVersion === null || pageVersion === void 0 ? void 0 : pageVersion.id });
    if (undefined !== res) {
        store.dispatch(setPageData((_b = res === null || res === void 0 ? void 0 : res.data) === null || _b === void 0 ? void 0 : _b.data));
    }
});
