import React, { StrictMode, Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import { setIframeHeight } from './Utils';
import { Provider } from 'react-redux';
import { createStore } from './rootStore';

window.setIframeHeight = setIframeHeight;

const AdminSite = lazy(() => import(/* webpackChunkName: "admin" */ './dashboard/src/App'));

const App = () => {
  return (
    <StrictMode>
      <Suspense fallback={<></>}>
        <Provider store={createStore()}>
          <AdminSite />
        </Provider>
      </Suspense>
    </StrictMode>
  );
};

const root = createRoot(document.getElementById('root'));
root.render(<App />);
