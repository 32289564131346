import Base from './Base';
class CTALog extends Base {
    constructor(props) {
        super(props);
        this.cta_log = props.cta_log;
        this.pagination = props.pagination;
        this.count_by_type = props.count_by_type;
    }
}
export default CTALog;
