var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createSlice } from '@reduxjs/toolkit';
import { get, some, isArray } from 'lodash-es';
import { createStore } from '../../../rootStore';
import { spinCountInc, spinCountDec } from '../redux/spinner/actions';
import PageVersionAPI from '../../../api/page_version';
import User from '../models/User';
import UserRecentItem from '../models/UserRecentItem';
import Instance from '../models/Instance';
import Site from '../models/Site';
import CustomTooltip from '../models/CustomTooltip';
import SiteStyleOverride from '../models/SiteStyleOverride';
import EmailSeries from '../models/EmailSeries';
import Trash from '../models/Trash';
import Component from '../models/Component';
import ComponentCategory from '../models/ComponentCategory';
import BackendErrorLogs from '../models/BackendErrorLogs';
import ContentTopicBriefing from '../models/ContentTopicBriefing';
import ContentTopic from '../models/ContentTopic';
import { getCookie } from '../../../Utils';
import Domain from '../models/Domain';
import Button from '../models/Button';
import CustomCode from '../models/CustomCode';
import Sector from '../models/Sector';
import Template from '../models/Template';
import CTALog from '../models/CTALog';
import Member from '../models/Member';
import MemberList from '../models/MemberList';
import SavedSearch from '../models/SavedSearch';
import Reports, { ReportConfig } from '../models/Reports';
import Section from '../models/Section';
import TextStyle from '../models/TextStyle';
import SiteBriefing from '../models/SiteBriefing';
import Page from '../models/Page';
import TemplateFragment from '../models/TemplateFragment';
const baseQueryWithSpinner = (args, api, extraOptions) => __awaiter(void 0, void 0, void 0, function* () {
    const store = createStore();
    const { pageData: { admin_domain } } = store.getState();
    const adminDomain = admin_domain ? `https://${admin_domain}` : '';
    // We have a tricky situation with the spinner... we want to be able to disable the spinner for some operations. Usually that's easy, we just pass disableSpinner in the args. But if a mutation triggers a cache invalidation then there is no way to disable the spinner on the resulting GET request. We are currently looking at the "forced" variable, because this is always true for GET requests that result from a cache invalidation. We are not currently using "forceRefetch", but if we ever do then these requests will always have the spinner disabled, and we will need to re-think how we do this.
    if (get(args, 'disableSpinner') !== true && api.forced !== true) {
        store.dispatch(spinCountInc());
    }
    // The X-User-Token is actually only used by the logout route, but there's no harm in adding it to all requests.
    const token = getCookie('USER_TOKEN');
    const headers = {};
    if (token) {
        headers['X-User-Token'] = token;
    }
    const result = yield fetchBaseQuery({
        baseUrl: `${adminDomain}/api/v1`,
        credentials: 'include',
        headers,
    })(args, api, extraOptions);
    if (get(args, 'disableSpinner') !== true && api.forced !== true) {
        store.dispatch(spinCountDec());
    }
    const resultData = result.data;
    if (extraOptions === null || extraOptions === void 0 ? void 0 : extraOptions.modelClass) {
        if (isArray(resultData === null || resultData === void 0 ? void 0 : resultData.data)) {
            return {
                data: {
                    data: resultData.data.map((d) => extraOptions.modelClass ? new extraOptions.modelClass(d) : d),
                    meta: resultData,
                },
            };
        }
        return {
            data: {
                data: resultData ? new extraOptions.modelClass(resultData === null || resultData === void 0 ? void 0 : resultData.data) : null,
                meta: resultData,
            },
        };
    }
    if (result.error) {
        return result;
    }
    return {
        data: {
            data: resultData === null || resultData === void 0 ? void 0 : resultData.data,
            meta: resultData,
        },
    };
});
export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithSpinner,
    tagTypes: [
        'instance',
        'site',
        'domain',
        'text_style',
        'button_style',
        'briefing',
        'sector',
        'section',
        'section_html',
        'tooltip',
        'page',
        'page_list',
        'orphan_page',
        'master_page',
        'page_version',
        'template',
        'url',
        'custom_code',
        'database_field',
        'database_search_field',
        'component_category',
        'page_data',
        'role',
        'user',
        'recent_item',
        'cta_log',
        'email_series',
        'saved_search',
        'member',
        'style_override',
        'job',
        'trash',
        'component',
        'backend_error_logs',
        'content_topic_briefing',
        'content_topic',
        'content_briefing',
        'page_url',
        'template_fragment',
    ],
    keepUnusedDataFor: 0,
    endpoints: (builder) => ({
        // Generic object endpoint. Don't want to cache these at the moment because they may interfere with the
        // non-generic endpoints.
        getObjectById: builder.query({
            query: ({ type, id }) => `/objects/${type}/${id}`,
        }),
        // Instances
        getInstances: builder.query({
            query: () => '/instances?order_by=company_name&order_direction=asc',
            extraOptions: { modelClass: Instance },
            providesTags: ['instance'],
        }),
        getInstanceById: builder.query({
            query: (instanceId) => `/instances/${instanceId}`,
            extraOptions: { modelClass: Instance },
            providesTags: ['instance'],
        }),
        updateInstance: builder.mutation({
            query: (params) => ({
                url: `/instances/${params.id}`,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: ['instance'],
            extraOptions: { modelClass: Instance },
        }),
        getMasterInstances: builder.query({
            query: () => '/instances/master_instances',
            providesTags: ['instance'],
        }),
        importInstanceFromLegacy: builder.mutation({
            query: ({ instanceId, siteId }) => ({
                url: `/instances/${instanceId}/import_instance?site_id=${siteId}`,
                method: 'POST',
            }),
            invalidatesTags: ['instance'],
        }),
        getInstanceData: builder.query({
            query: (instanceId) => `/instances/${instanceId}/instance_data`,
        }),
        duplicateInstance: builder.mutation({
            query: (params) => ({
                url: `/instances/${params.id}/duplicate`,
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['instance'],
        }),
        // Sites
        getSiteById: builder.query({
            query: (siteId) => `/sites/${siteId}`,
            providesTags: ['site'],
            extraOptions: { modelClass: Site },
        }),
        getSites: builder.query({
            query: (instanceId) => `/sites?instance_id=${instanceId}`,
            extraOptions: { modelClass: Site },
            providesTags: ['site'],
            transformResponse: (data) => {
                var _a;
                (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.sort((o1, o2) => {
                    // We want to put these at the bottom of the list: sites without domains, ACE sites, old mobile (m.) sites, and .wb.gs sites.
                    const bottoms = [
                        /^$/,
                        /^ace-/,
                        /\.ace-/,
                        /^m\./,
                        /\.wb\.gs/,
                    ];
                    if (typeof o1.label !== 'string') {
                        o1.label = '';
                    }
                    if (typeof o2.label !== 'string') {
                        o2.label = '';
                    }
                    const anyMatch1 = some(bottoms, (b) => o1.label.match(b));
                    const anyMatch2 = some(bottoms, (b) => o2.label.match(b));
                    if (anyMatch1 && anyMatch2) {
                        return 0;
                    }
                    if (anyMatch1) {
                        return 1;
                    }
                    if (anyMatch2) {
                        return -1;
                    }
                    return o1.label > o2.label ? 1 : -1;
                });
                return data;
            },
        }),
        getAllSites: builder.query({
            query: () => ({
                url: `/sites`,
                method: 'GET',
            }),
            extraOptions: { modelClass: Site },
        }),
        createSite: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload}/sites`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['site'],
            extraOptions: { modelClass: Site },
        }),
        deleteSite: builder.mutation({
            query: ({ instance_id, id }) => ({
                url: `/instances/${instance_id}/sites/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['site'],
            extraOptions: { modelClass: Site },
        }),
        updateSite: builder.mutation({
            query: (params) => ({
                url: `/instances/${params.instance_id}/sites/${params.id}`,
                method: 'PATCH',
                body: params,
            }),
            invalidatesTags: ['site'],
            extraOptions: { modelClass: Site },
        }),
        sitesLinkingToMe: builder.query({
            query: ({ instanceId, siteId }) => ({
                url: `/instances/${instanceId}/sites/${siteId}/linking_to_me`,
                method: 'GET',
            }),
        }),
        // Domains
        getDomains: builder.query({
            query: (siteId) => `/sites/${siteId}/domains`,
            providesTags: ['domain'],
            extraOptions: { modelClass: Domain },
        }),
        getAllDomains: builder.query({
            query: ({ domainName, disableSpinner }) => ({
                url: `/domains?domain_name=${domainName}`,
                method: 'GET',
                disableSpinner,
            }),
            providesTags: ['domain'],
        }),
        addNewDomain: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/domains`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['domain'],
            extraOptions: { modelClass: Domain },
        }),
        updateDomain: builder.mutation({
            query: (domain) => ({
                url: `/sites/${domain.site_id}/domains/${domain.id}`,
                method: 'PATCH',
                body: domain,
            }),
            invalidatesTags: ['domain', 'site'], // Adding 'site' so that the site selector updates when changing domains
            extraOptions: { modelClass: Domain },
        }),
        deleteDomain: builder.mutation({
            query: ({ site_id, id }) => ({
                url: `/sites/${site_id}/domains/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['domain'],
        }),
        makeDomainPrimary: builder.mutation({
            query: (domain) => ({
                url: `/sites/${domain.site_id}/domains/${domain.id}/make_primary`,
                method: 'POST',
            }),
            invalidatesTags: ['domain', 'site'], // Adding 'site' so that the site selector updates when changing domains
        }),
        issueDomainCertificate: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/domains/${payload.id}/issue_certificate`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['domain'],
        }),
        // Pages
        getPages: builder.query({
            query: ({ instanceId, siteId }) => `/sites/${siteId}/pages?instance_id=${instanceId}`,
            providesTags: ['page_list'],
            extraOptions: { modelClass: Page },
        }),
        getPage: builder.query({
            query: ({ id, instanceId, siteId }) => `/sites/${siteId}/pages/${id}?instance_id=${instanceId}`,
            providesTags: ['page'],
            extraOptions: { modelClass: Page },
        }),
        getOrphanPages: builder.query({
            query: ({ instanceId, siteId }) => `/sites/${siteId}/orphan_pages?instance_id=${instanceId}`,
            providesTags: ['orphan_page'],
            extraOptions: { modelClass: Page },
        }),
        getMasterPages: builder.query({
            query: ({ instanceId, siteId }) => `/sites/${siteId}/master_pages?instance_id=${instanceId}`,
            providesTags: ['master_page'],
            extraOptions: { modelClass: Page },
        }),
        getPageSections: builder.query({
            query: ({ instanceId, siteId, pageId }) => `/sites/${siteId}/pages/${pageId}/sections?instance_id=${instanceId}`,
        }),
        addNewPage: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/pages`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: (result, error, args) => {
                if ((args === null || args === void 0 ? void 0 : args.type) === 'master') {
                    return ['master_page'];
                }
                if ((args === null || args === void 0 ? void 0 : args.type) === 'normal' && !(args === null || args === void 0 ? void 0 : args.parent_id)) {
                    return ['orphan_page'];
                }
                return ['page_list'];
            },
            extraOptions: { modelClass: Page },
        }),
        deletePage: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/pages/${payload.current_page}?instance_id=${payload.instance_id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, args) => {
                if ((args === null || args === void 0 ? void 0 : args.type) === 'master') {
                    return ['master_page'];
                }
                if ((args === null || args === void 0 ? void 0 : args.type) === 'normal' && !(args === null || args === void 0 ? void 0 : args.parent_id)) {
                    return ['orphan_page'];
                }
                return ['page_list'];
            },
            extraOptions: { modelClass: Page },
        }),
        pageUpdate: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/pages/${payload === null || payload === void 0 ? void 0 : payload.page_id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['page_list', 'page_data', 'section'],
            extraOptions: { modelClass: Page },
        }),
        duplicatePage: builder.mutation({
            query: (params) => ({
                url: `/pages/${params.id}/duplicate`,
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['page_list'],
            extraOptions: { modelClass: Page },
        }),
        getPageTree: builder.query({
            query: ({ instanceId, siteId }) => `/instances/${instanceId}/sites/${siteId}/page_tree`,
        }),
        pageBulkEdit: builder.mutation({
            query: (payload) => ({
                url: `sites/${payload.site_id}/pages/bulk_edit`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['page_list', 'page_data', 'section'],
            // extraOptions: { modelClass: Page },
        }),
        // Email Pages
        getEmails: builder.query({
            query: ({ instanceId }) => `/instances/${instanceId}/email_pages`,
            providesTags: ['page_list'],
            extraOptions: { modelClass: Page },
        }),
        emailPageUpdate: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload.instance_id}/email_pages/${payload === null || payload === void 0 ? void 0 : payload.page_id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['page_list', 'page_data', 'section'],
            extraOptions: { modelClass: Page },
        }),
        emailPageDuplicate: builder.mutation({
            query: (params) => ({
                url: `/instances/${params.instance_id}/email_pages/${params.id}/duplicate`,
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['page_list'],
            extraOptions: { modelClass: Page },
        }),
        emailPageCreate: builder.mutation({
            query: (params) => ({
                url: `/instances/${params.instance_id}/email_pages`,
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['page_list'],
            extraOptions: { modelClass: Page },
        }),
        emailPageDelete: builder.mutation({
            query: (params) => ({
                url: `/instances/${params.instance_id}/email_pages/${params.id}`,
                method: 'DELETE',
                body: params,
            }),
            invalidatesTags: ['page_list'],
            extraOptions: { modelClass: Page },
        }),
        emailPageProof: builder.mutation({
            query: (params) => ({
                url: `/instances/${params.instance_id}/email_pages/${params.id}/email_proof`,
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['page_list'],
        }),
        // Page Versions
        getPageVersions: builder.query({
            query: (_a) => {
                var { pageId, siteId } = _a, payload = __rest(_a, ["pageId", "siteId"]);
                return `/sites/${siteId}/pages/${pageId}/page_versions?${new URLSearchParams(payload)}`;
            },
            providesTags: ['page_version'],
        }),
        getPageVersion: builder.query({
            query: ({ id, pageId, instanceId, siteId, }) => `/sites/${siteId}/pages/${pageId}/page_versions/${id}?instance_id=${instanceId}`,
            providesTags: ['page_version'],
        }),
        getEmailPageVersions: builder.query({
            query: ({ pageId, instanceId }) => `/instances/${instanceId}/email_pages/${pageId}/page_versions`,
            providesTags: ['page_version'],
        }),
        pageVersionCreate: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/pages/${payload.page_id}/page_versions/`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['page_version'],
        }),
        pageVersionDelete: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.siteId}/pages/${payload.pageId}/page_versions/${payload.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['page_version'],
        }),
        pageVersionPublish: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/pages/${payload.page_id}/page_versions/${payload.id}/change_publish_draft`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['page_version'],
        }),
        pageVersionUpdate: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload === null || payload === void 0 ? void 0 : payload.site_id}/pages/${payload === null || payload === void 0 ? void 0 : payload.page_id}/page_versions/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['page_version', 'page_data', 'section'],
        }),
        // URLs
        getURLById: builder.query({
            query: (id) => `/urls/${id}`,
            providesTags: ['url'],
        }),
        // Sectors
        getAllSectorsFromSite: builder.query({
            query: ({ instanceId, siteId }) => `/instances/${instanceId}/sites/${siteId}/sectors`,
            providesTags: ['sector'],
        }),
        sectorCreate: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload.instance_id}/sectors`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['sector', 'template'],
            extraOptions: { modelClass: Sector },
        }),
        sectorUpdate: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload.instance_id}/sectors/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['template'],
            extraOptions: { modelClass: Sector },
        }),
        sectorDelete: builder.mutation({
            query: (params) => ({
                url: `/instances/${params.instanceId}/sectors/${params.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['template'],
        }),
        sectorPosition: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload.instance_id}/sectors/update_position`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['template'],
            extraOptions: { modelClass: Sector },
        }),
        // Sections
        getSections: builder.query({
            query: (params) => `/sites/${params === null || params === void 0 ? void 0 : params.siteId}/pages/${params === null || params === void 0 ? void 0 : params.pageId}/page_versions/${params === null || params === void 0 ? void 0 : params.pageVersionId}/sections${(params === null || params === void 0 ? void 0 : params.includeContent) ? '?include_content=true' : ''}`,
            providesTags: (result) => (result
                ? [
                    ...result.data.map(({ id }) => ({ type: 'section', id })),
                    { type: 'section', id: 'LIST' },
                ]
                : [{ type: 'section', id: 'LIST' }]),
            extraOptions: { modelClass: Section },
        }),
        getMasterSections: builder.query({
            query: (params) => `/sites/${params === null || params === void 0 ? void 0 : params.siteId}/pages/${params === null || params === void 0 ? void 0 : params.pageId}/page_versions/${params === null || params === void 0 ? void 0 : params.pageVersionId}/master_sections`,
            providesTags: (result) => (result
                ? [
                    ...result.data.map(({ id }) => ({ type: 'section', id })),
                    { type: 'section', id: 'LIST' },
                ]
                : [{ type: 'section', id: 'LIST' }]),
            extraOptions: { modelClass: Section },
        }),
        getSectionHTML: builder.query({
            query: (params) => `/instances/${params === null || params === void 0 ? void 0 : params.instanceId}/sections/${params === null || params === void 0 ? void 0 : params.sectionId}/html`,
            providesTags: (result, error, args) => [{ type: 'section_html', id: args.sectionId }],
        }),
        sectionCreate: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload.instance_id}/sections`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['page_data', { type: 'section', id: 'LIST' }],
        }),
        getSection: builder.query({
            query: (params) => `/instances/${params === null || params === void 0 ? void 0 : params.instanceId}/sections/${params === null || params === void 0 ? void 0 : params.sectionId}`,
            providesTags: (result, error, args) => [{ type: 'section', id: args.sectionId }],
            extraOptions: { modelClass: Section },
        }),
        sectionUpdate: builder.mutation({
            query: (_a) => {
                var { instance_id, id } = _a, payload = __rest(_a, ["instance_id", "id"]);
                return ({
                    url: `/instances/${instance_id}/sections/${id}`,
                    method: 'PUT',
                    body: payload,
                });
            },
            invalidatesTags: (result, error, args) => {
                var _a, _b;
                return ([
                    ...['page_data', { type: 'section', id: args.id }, { type: 'section_html', id: args.id }],
                    ...(((_a = args === null || args === void 0 ? void 0 : args.column_section) === null || _a === void 0 ? void 0 : _a.id) ? [{ type: 'section', id: args === null || args === void 0 ? void 0 : args.column_section.id }] : []),
                    ...(((_b = args === null || args === void 0 ? void 0 : args.column_section) === null || _b === void 0 ? void 0 : _b.id) ? [{ type: 'section_html', id: args === null || args === void 0 ? void 0 : args.column_section.id }] : []),
                    ...((args === null || args === void 0 ? void 0 : args.column_attributes) ? args.column_attributes.map((c) => ({ type: 'section', id: c.id })) : []),
                    ...((args === null || args === void 0 ? void 0 : args.column_attributes) ? args.column_attributes.map((c) => ({ type: 'section_html', id: c.id })) : []),
                ]);
            },
            extraOptions: { modelClass: Section },
        }),
        sectionDelete: builder.mutation({
            query: ({ instance_id, id }) => ({
                url: `/instances/${instance_id}/sections/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['page_data', { type: 'section', id: 'LIST' }],
        }),
        getColumns: builder.query({
            query: ({ instanceId, sectionId }) => `/instances/${instanceId}/sections/${sectionId}/columns`,
            providesTags: (result) => (result
                ? [
                    ...result.data.map(({ id }) => ({ type: 'section', id })),
                    { type: 'section', id: 'LIST' },
                ]
                : [{ type: 'section', id: 'LIST' }]),
        }),
        sectionPositionUpdate: builder.mutation({
            query: (_a) => {
                var { instance_id } = _a, payload = __rest(_a, ["instance_id"]);
                return ({
                    url: `/instances/${instance_id}/sections/update_position`,
                    method: 'PUT',
                    body: payload,
                });
            },
            invalidatesTags: ['page_data', { type: 'section', id: 'LIST' }],
            extraOptions: { modelClass: Section },
        }),
        getSectionsByType: builder.query({
            query: ({ instanceId, siteId, type, version, }) => `/instances/${instanceId}/sites/${siteId}/sections?type=${type}&version=${version}`,
        }),
        duplicateSection: builder.mutation({
            query: (_a) => {
                var { instanceId } = _a, params = __rest(_a, ["instanceId"]);
                return ({
                    url: `/instances/${instanceId}/sections/duplicate`,
                    method: 'POST',
                    body: params,
                });
            },
            invalidatesTags: [{ type: 'section', id: 'LIST' }],
        }),
        // Text Styles
        getTextStyles: builder.query({
            query: (siteId) => `/sites/${siteId}/text_styles`,
            providesTags: ['text_style'],
            extraOptions: { modelClass: TextStyle },
        }),
        getTextStyleById: builder.query({
            query: (params) => `/sites/${params === null || params === void 0 ? void 0 : params.site_id}/text_styles/${params === null || params === void 0 ? void 0 : params.id}`,
            providesTags: ['text_style'],
            extraOptions: { modelClass: TextStyle },
        }),
        addNewTextStyle: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/text_styles`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['text_style'],
            extraOptions: { modelClass: TextStyle },
        }),
        updateTextStyle: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload === null || payload === void 0 ? void 0 : payload.site_id}/text_styles/${payload === null || payload === void 0 ? void 0 : payload.id}`,
                method: 'PATCH',
                body: payload,
            }),
            invalidatesTags: ['text_style'],
            extraOptions: { modelClass: TextStyle },
        }),
        deleteTextStyle: builder.mutation({
            query: (params) => ({
                url: `/sites/${params === null || params === void 0 ? void 0 : params.site_id}/text_styles/${params === null || params === void 0 ? void 0 : params.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['text_style'],
            extraOptions: { modelClass: TextStyle },
        }),
        // Custom Fonts
        getCustomFonts: builder.query({
            query: () => '/custom_fonts',
        }),
        // Load Media Tool
        getMediaFiles: builder.query({
            query: (instanceId) => `/instances/${instanceId}/load_media_tool`,
        }),
        // Button Styles
        getButtonStyles: builder.query({
            query: (siteId) => `/sites/${siteId}/button_styles`,
            providesTags: ['button_style'],
            extraOptions: { modelClass: Button },
        }),
        getButtonStyleById: builder.query({
            query: (params) => `/sites/${params === null || params === void 0 ? void 0 : params.site_id}/button_styles/${params === null || params === void 0 ? void 0 : params.id}`,
            providesTags: ['button_style'],
            extraOptions: { modelClass: Button },
        }),
        addButtonStyle: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/button_styles`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['button_style'],
            extraOptions: { modelClass: Button },
        }),
        updateButtonStyle: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload === null || payload === void 0 ? void 0 : payload.site_id}/button_styles/${payload === null || payload === void 0 ? void 0 : payload.id}`,
                method: 'PATCH',
                body: payload,
            }),
            invalidatesTags: ['button_style'],
            extraOptions: { modelClass: Button },
        }),
        deleteButtonStyle: builder.mutation({
            query: (params) => ({
                url: `/sites/${params === null || params === void 0 ? void 0 : params.site_id}/button_styles/${params === null || params === void 0 ? void 0 : params.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['button_style'],
            extraOptions: { modelClass: Button },
        }),
        // Member email logs
        getMemberEmailLogs: builder.query({
            query: (params) => `/instances/${params === null || params === void 0 ? void 0 : params.instance_id}/members/${params === null || params === void 0 ? void 0 : params.member_id}/email_logs`,
        }),
        // Export Members
        exportMembers: builder.query({
            query: (params) => `/searches/${params === null || params === void 0 ? void 0 : params.id}/export_members?export_type=${params === null || params === void 0 ? void 0 : params.type}&instance_id=${params === null || params === void 0 ? void 0 : params.instance_id}`,
        }),
        // Members
        getMembers: builder.query({
            query: (params) => `/instances/${params === null || params === void 0 ? void 0 : params.instance_id}/members?page=${params.page}&order_by=${params.order_by}&order_direction=${params.order_direction}&page_size=${params.page_size}`,
            providesTags: (result) => [
                'member',
                ...result.data.members.map(({ id }) => ({ type: 'member', id })),
            ],
            extraOptions: { modelClass: MemberList },
        }),
        getMember: builder.query({
            query: (params) => `/instances/${params.instance_id}/members/${params.id}`,
            providesTags: (result, error, { id }) => [{ type: 'member', id }],
            extraOptions: { modelClass: Member },
        }),
        createMember: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload.instance_id}/members`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'member', id }],
            extraOptions: { modelClass: Member },
        }),
        updateMember: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload.instance_id}/members/${payload.member_id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'member', id }],
            extraOptions: { modelClass: Member },
        }),
        deleteMember: builder.mutation({
            query: ({ instanceId, id }) => ({
                url: `/instances/${instanceId}/members/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['member'],
        }),
        deleteSearchMember: builder.mutation({
            query: (id) => ({
                url: `/searches/${id}/delete_members`,
                method: 'POST',
            }),
            invalidatesTags: ['member'],
        }),
        getMemberSearchById: builder.query({
            query: (params) => `/instances/${params.instance_id}/searches/${params.id || '-'}/result?page=${params.page}&order_by=${params.order_by}&order_direction=${params.order_direction}&page_size=${params.page_size}`,
            providesTags: ['member'],
        }),
        memberSearchCreate: builder.mutation({
            query: (payload) => ({
                url: '/searches',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['member'],
        }),
        memberSearchUpdate: builder.mutation({
            query: (payload) => ({
                url: `/searches/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['member'],
        }),
        // Site Briefing
        createSiteBriefing: builder.mutation({
            query: () => ({
                url: '/briefings',
                method: 'POST',
            }),
            invalidatesTags: ['briefing'],
        }),
        getSiteBriefings: builder.query({
            query: (params) => ({
                url: `/briefings${(params === null || params === void 0 ? void 0 : params.siteId) ? `?site_id=${params === null || params === void 0 ? void 0 : params.siteId}` : ''}`,
                params,
                method: 'GET',
            }),
            providesTags: (result) => [
                'briefing',
                ...result.data.briefings.map(({ id }) => ({ type: 'briefing', id })),
            ],
        }),
        getSiteBriefing: builder.query({
            query: (id) => `/briefings/${id}`,
            providesTags: (result, error, args) => [{ type: 'briefing', id: args.id }],
            extraOptions: { modelClass: SiteBriefing },
        }),
        deleteSiteBriefing: builder.mutation({
            query: (briefing) => ({
                url: `/briefings/${briefing.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['briefing'],
        }),
        updateSiteBriefing: builder.mutation({
            query: (_a) => {
                var { disableSpinner } = _a, briefing = __rest(_a, ["disableSpinner"]);
                return ({
                    url: `/briefings/${briefing.id}`,
                    method: 'PATCH',
                    body: briefing,
                    disableSpinner,
                });
            },
            onQueryStarted(patch, { dispatch, queryFulfilled }) {
                dispatch(apiSlice.util.updateQueryData('getSiteBriefing', patch.id, (briefing) => Object.assign(briefing, patch)));
                dispatch(apiSlice.util.invalidateTags([{ type: 'briefing', id: 'LIST' }]));
                queryFulfilled.catch(() => dispatch(apiSlice.util.invalidateTags(['briefing'])));
            },
            extraOptions: { modelClass: SiteBriefing },
        }),
        addImageToBriefing: builder.mutation({
            query: ({ disableSpinner, briefing, name, file, }) => {
                const body = new FormData();
                body.append('name', name);
                body.append('file', file);
                return {
                    url: `/briefings/${briefing.id}/image`,
                    method: 'POST',
                    body,
                    disableSpinner,
                };
            },
            invalidatesTags: ['briefing'],
        }),
        generateInstanceFromSiteBriefing: builder.mutation({
            query: ({ briefing, content }) => ({
                url: `/briefings/${briefing.id}/generate_instance?content=${content ? 1 : 0}`,
                method: 'POST',
            }),
            invalidatesTags: ['briefing'],
        }),
        generateContentFromSiteBriefing: builder.mutation({
            query: (briefing) => ({
                url: `/briefings/${briefing.id}/generate_content`,
                method: 'POST',
            }),
            invalidatesTags: ['briefing'],
        }),
        generateQuestions: builder.query({
            query: ({ briefingId, productPageId, subPageId }) => `/briefings/${briefingId}/questions?product_page_id=${productPageId}&sub_page_id=${subPageId}`,
        }),
        // Misc
        getSpelling: builder.query({
            query: () => '/spelling',
        }),
        // Link Tool
        loadLinkTool: builder.query({
            query: (id) => `/instances/${id}/load_link_tool`,
        }),
        // Reports
        getReportConfig: builder.query({
            query: () => '/reports/config',
            extraOptions: { modelClass: ReportConfig },
        }),
        getReportData: builder.query({
            query: (config) => ({
                url: '/reports/data',
                method: 'POST',
                body: config,
            }),
            extraOptions: { modelClass: Reports },
        }),
        // Templates
        getTemplates: builder.query({
            query: (params) => `/sites/${params === null || params === void 0 ? void 0 : params.siteId}/templates?instance_id=${params === null || params === void 0 ? void 0 : params.instanceId}`,
            providesTags: (result) => [
                'template',
                ...result.data.map(({ id }) => ({ type: 'template', id })),
            ],
            extraOptions: { modelClass: Template },
        }),
        getTemplate: builder.query({
            query: (params) => `/sites/${params === null || params === void 0 ? void 0 : params.siteId}/templates/${params === null || params === void 0 ? void 0 : params.id}?instance_id=${params === null || params === void 0 ? void 0 : params.instanceId}`,
            providesTags: (result, error, args) => [{ type: 'template', id: args.id }],
        }),
        duplicateTemplates: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/templates/${payload.id}/duplicate`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'template', id }],
        }),
        createTemplate: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/templates`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'template', id }],
            extraOptions: { modelClass: Template },
        }),
        updateTemplate: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/templates/${payload.id}`,
                method: 'PATCH',
                body: payload,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'template', id }],
            extraOptions: { modelClass: Template },
        }),
        deleteTemplate: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/templates/${payload.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'template', id }],
        }),
        // Custom Tooltips
        getCustomTooltips: builder.query({
            query: () => '/custom_tooltips',
            extraOptions: { modelClass: CustomTooltip },
            providesTags: ['tooltip'],
        }),
        createCustomTooltip: builder.mutation({
            query: (payload) => ({
                url: '/custom_tooltips',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['tooltip'],
        }),
        updateCustomTooltip: builder.mutation({
            query: (payload) => ({
                url: `/custom_tooltips/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['tooltip'],
        }),
        // Email Series
        getEmailSeries: builder.query({
            query: (instanceId) => `/instances/${instanceId}/email_series`,
            extraOptions: { modelClass: EmailSeries },
            providesTags: (result) => [
                'email_series',
                ...result.data.map(({ id }) => ({ type: 'email_series', id })),
            ],
        }),
        getEmailSeriesById: builder.query({
            query: (payload) => `/instances/${payload.instance_id}/email_series/${payload.id}`,
            extraOptions: { modelClass: EmailSeries },
            providesTags: (result, error, args) => [{ type: 'email_series', id: args.id }],
        }),
        createEmailSeries: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload.instance_id}/email_series`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['email_series'],
        }),
        deleteEmailSeries: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload.instance_id}/email_series/${payload.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'email_series', id }],
        }),
        updateEmailSeries: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload.instance_id}/email_series/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            extraOptions: { modelClass: EmailSeries },
            invalidatesTags: (result, error, { id }) => [{ type: 'email_series', id }],
        }),
        emailSeriesSubscribeMember: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload.instance_id}/searches/${payload.id}/subscribe_search_members_to_email_series`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['email_series'],
        }),
        // Custom Codes
        getCustomCodes: builder.query({
            query: (siteId) => `/sites/${siteId}/custom_codes`,
            providesTags: (result) => [
                'custom_code',
                ...result.data.map(({ id }) => ({ type: 'custom_code', id })),
            ],
            extraOptions: { modelClass: CustomCode },
        }),
        getCustomCode: builder.query({
            query: (params) => `/sites/${params === null || params === void 0 ? void 0 : params.site_id}/custom_codes/${params === null || params === void 0 ? void 0 : params.id}`,
            providesTags: (result, error, args) => [{ type: 'custom_code', id: args.id }],
            extraOptions: { modelClass: CustomCode },
        }),
        createCustomCode: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload === null || payload === void 0 ? void 0 : payload.site_id}/custom_codes`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: (result) => { var _a; return [{ type: 'custom_code', id: (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.id }]; },
            extraOptions: { modelClass: CustomCode },
        }),
        deleteCustomCode: builder.mutation({
            query: (params) => ({
                url: `/sites/${params === null || params === void 0 ? void 0 : params.siteId}/custom_codes/${params === null || params === void 0 ? void 0 : params.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'custom_code', id }],
            extraOptions: { modelClass: CustomCode },
        }),
        updateCustomCode: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload === null || payload === void 0 ? void 0 : payload.site_id}/custom_codes/${payload === null || payload === void 0 ? void 0 : payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'custom_code', id }],
            extraOptions: { modelClass: CustomCode },
        }),
        // Database Fields
        getDatabaseFields: builder.query({
            query: (params) => `/instances/${params === null || params === void 0 ? void 0 : params.instanceId}/database_fields`,
            providesTags: (result) => [
                'database_field',
                ...result.data.map(({ id }) => ({ type: 'database_field', id })),
            ],
        }),
        getDatabaseField: builder.query({
            query: (params) => `/instances/${params.instance_id}/database_fields/${params.field_id}`,
            providesTags: (result, error, args) => [{ type: 'database_field', id: args.id }],
        }),
        createDatabaseField: builder.mutation({
            query: (instanceId) => ({
                url: `/instances/${instanceId}/database_fields`,
                method: 'POST',
            }),
            invalidatesTags: ['database_field'],
        }),
        updateDatabaseField: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload.instance_id}/database_fields/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'database_field', id }],
        }),
        deleteDatabaseField: builder.mutation({
            query: (params) => ({
                url: `/instances/${params.instance_id}/database_fields/${params.field_id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'database_field', id }],
        }),
        positionDatabaseField: builder.mutation({
            query: (payload) => ({
                url: `/instances/${payload.instance_id}/database_fields/update_position`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['database_field'],
        }),
        getDatabaseSearchFields: builder.query({
            query: (params) => `/instances/${params === null || params === void 0 ? void 0 : params.instanceId}/database_fields/field_search`,
            providesTags: ['database_search_field'],
        }),
        // Saved Search
        getSavedSearches: builder.query({
            query: (instanceId) => `/instances/${instanceId}/searches`,
            providesTags: (result) => [
                'saved_search',
                ...result.data.map(({ id }) => ({ type: 'saved_search', id })),
            ],
            extraOptions: { modelClass: SavedSearch },
        }),
        getSavedSearch: builder.query({
            query: (searchId) => `/searches/${searchId}`,
            providesTags: (result, error, args) => [{ type: 'database_field', id: args.id }],
        }),
        createSavedSearch: builder.mutation({
            query: (payload) => ({
                url: `/searches/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['saved_search'],
            extraOptions: { modelClass: SavedSearch },
        }),
        deleteSavedSearches: builder.mutation({
            query: (id) => ({
                url: `/searches/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'saved_search', id }],
            extraOptions: { modelClass: SavedSearch },
        }),
        // Component Categories
        getComponentCategories: builder.query({
            query: () => '/component_categories',
            extraOptions: { modelClass: ComponentCategory },
            providesTags: (result) => [
                'component_category',
                ...result.data.map(({ id }) => ({ type: 'component_category', id })),
            ],
        }),
        getComponentCategory: builder.query({
            query: (id) => `/component_categories/${id}`,
            providesTags: (result, error, args) => [{ type: 'component_category', id: args.id }],
            extraOptions: { modelClass: ComponentCategory },
        }),
        updateComponentCategory: builder.mutation({
            query: (payload) => ({
                url: `/component_categories/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['component_category'],
            extraOptions: { modelClass: ComponentCategory },
        }),
        createComponentCategory: builder.mutation({
            query: (payload) => ({
                url: '/component_categories',
                method: 'POST',
                params: payload,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'component_category', id }],
            extraOptions: { modelClass: ComponentCategory },
        }),
        deleteComponentCategory: builder.mutation({
            query: (params) => ({
                url: `/component_categories/${params === null || params === void 0 ? void 0 : params.id}`,
                params,
                method: 'DELETE',
            }),
            invalidatesTags: ['component_category'],
        }),
        // Components
        getComponents: builder.query({
            query: (params) => ({
                url: '/components',
                method: 'GET',
                params,
            }),
            extraOptions: { modelClass: Component },
            providesTags: (result, error, args) => [{ type: 'component', id: args.id }],
        }),
        getComponent: builder.query({
            query: (id) => ({
                url: `/components/${id}`,
                method: 'GET',
            }),
            extraOptions: { modelClass: Component },
            providesTags: (result, error, args) => [{ type: 'component', id: args.id }],
        }),
        duplicateComponent: builder.mutation({
            query: (id) => ({
                url: `/components/${id}/duplicate`,
                method: 'POST',
            }),
            extraOptions: { modelClass: Component },
            invalidatesTags: ['component'],
        }),
        deleteComponent: builder.mutation({
            query: (params) => ({
                url: `/components/${params === null || params === void 0 ? void 0 : params.id}`,
                params,
                method: 'DELETE',
            }),
            invalidatesTags: ['component'],
        }),
        createComponent: builder.mutation({
            query: (payload) => ({
                url: `/components`,
                method: 'POST',
                params: payload,
            }),
            extraOptions: { modelClass: Component },
            invalidatesTags: (result, error, { id }) => [{ type: 'component', id }],
        }),
        updateComponent: builder.mutation({
            query: (payload) => ({
                url: `/components/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            extraOptions: { modelClass: Component },
            invalidatesTags: (result, error, { id }) => [{ type: 'component', id }],
        }),
        // Page Data
        getPageData: builder.query({
            query: (params) => `/sites/${params === null || params === void 0 ? void 0 : params.siteId}/pages/${params === null || params === void 0 ? void 0 : params.pageId}/page_versions/${params === null || params === void 0 ? void 0 : params.pageVersionId}/objects`,
            providesTags: ['page_data'],
        }),
        // Admin Roles
        getRoles: builder.query({
            query: () => '/roles',
            providesTags: ['role'],
        }),
        // Users
        getUser: builder.query({
            query: (id) => `/users/${id}`,
            providesTags: ['user'],
            extraOptions: { modelClass: User },
        }),
        getUsers: builder.query({
            query: () => '/users',
            providesTags: ['user'],
            extraOptions: { modelClass: User },
        }),
        createUser: builder.mutation({
            query: (params) => ({
                url: '/users',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['user'],
            extraOptions: { modelClass: User },
        }),
        updateUser: builder.mutation({
            query: (params) => ({
                url: `/users/${params.id}`,
                method: 'PUT',
                body: params,
            }),
            invalidatesTags: ['user'],
        }),
        deleteUser: builder.mutation({
            query: (params) => ({
                url: `/users/${params.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['user'],
        }),
        // Current User Profile
        loginUserEmail: builder.mutation({
            query: (params) => ({
                url: '/login',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['user'],
        }),
        loginUserGoogle: builder.mutation({
            query: (params) => ({
                url: '/google_login',
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['user'],
        }),
        getUserProfile: builder.query({
            query: () => '/profile',
            providesTags: ['user'],
            // This is only used to figure out if the user is logged into the dashboard. It returns a 404 if they are not,
            // but we don't want to display an error message, so we ignore the 404.
            // TODO: find another way to do this
            transformErrorResponse: () => null,
            extraOptions: { modelClass: User },
        }),
        logoutUser: builder.mutation({
            query: (params) => ({
                url: '/logout',
                method: 'DELETE',
                body: params,
            }),
            invalidatesTags: ['user'],
        }),
        // TextBlock
        getTextBlockHistory: builder.query({
            query: (id) => `/history_trackers/${id}`,
        }),
        // Geographic Area
        getGeographicArea: builder.query({
            query: () => `/geo_areas`,
        }),
        // Business Niches
        getBusinessNiches: builder.query({
            query: () => `/business_niches`,
        }),
        // Geographic Code
        getGeographicCode: builder.query({
            query: (params) => `/geocode/?address=${params.address}`,
        }),
        // Recent Items
        getRecentItems: builder.query({
            query: (params) => ({
                url: `/users/${params.userId}/recent_items?instance_id=${params.instanceId}`,
                method: 'GET',
                disableSpinner: true,
            }),
            extraOptions: { modelClass: UserRecentItem },
            providesTags: ['recent_item'],
        }),
        updateRecentItems: builder.mutation({
            query: (params) => ({
                url: `/users/${params.userId}/recent_items`,
                method: 'POST',
                body: { items: params.items },
                disableSpinner: true,
            }),
            invalidatesTags: ['recent_item'],
        }),
        // CTAs
        getChatTranscript: builder.query({
            query: (params) => `/instances/${params.instanceId}/cta_logs/${params.id}/transcript`,
            providesTags: ['cta_log'],
        }),
        getCtaLogs: builder.query({
            query: (params) => {
                // Convert "types" into "types[]" so that Rails can read the array
                // TODO: Maybe make this part of the fetchBaseQuery
                const tempParams = Object.assign({}, params);
                if (tempParams.types) {
                    tempParams['types[]'] = tempParams.types;
                    delete tempParams.types;
                }
                return {
                    url: `/instances/${params.instance_id}/cta_logs`,
                    params: tempParams,
                    method: 'GET',
                };
            },
            providesTags: ['cta_log'],
            extraOptions: { modelClass: CTALog },
        }),
        getCtaLog: builder.query({
            query: (params) => ({
                url: `/instances/${params.instance_id}/cta_logs/${params.cta_log_id}`,
                params,
                method: 'GET',
            }),
            providesTags: ['cta_log'],
            extraOptions: { modelClass: CTALog },
        }),
        deleteCtaLog: builder.mutation({
            query: (params) => ({
                url: `/instances/${params.instance_id}/cta_logs/${params.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['cta_log'],
        }),
        markSpamCtaLog: builder.mutation({
            query: (params) => ({
                url: `/instances/${params.instance_id}/cta_logs/${params.id}/approve`,
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['cta_log'],
            extraOptions: { modelClass: CTALog },
        }),
        // Style Override
        getStyleOverrides: builder.query({
            query: (siteId) => `/sites/${siteId}/break_points`,
            extraOptions: { modelClass: SiteStyleOverride },
            providesTags: (result) => [
                'style_override',
                ...result.data.map(({ id }) => ({ type: 'style_override', id })),
            ],
        }),
        createStyleOverride: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/break_points`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['style_override'],
        }),
        updateStyleOverride: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/break_points`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'style_override', id }],
        }),
        deleteStyleOverride: builder.mutation({
            query: (params) => ({
                url: `/sites/${params.site_id}/break_points/${params.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: (result, error, { id }) => [{ type: 'style_override', id }],
        }),
        // Automated Reports
        automatedReports: builder.query({
            query: (params) => `/instances/${params.instanceId}/job_list`,
            providesTags: (result) => [
                'job',
                ...result.data.map(({ id }) => ({ type: 'job', id })),
            ],
        }),
        runAutomatedReport: builder.mutation({
            query: (params) => ({
                url: `/instances/${params.instance_id}/run_job/${params.job_id}`,
                method: 'POST',
                body: params,
            }),
            invalidatesTags: ['job'],
        }),
        // Trash
        getTrashes: builder.query({
            query: (params) => ({
                url: `/trashes`,
                params,
                method: 'GET',
            }),
            extraOptions: { modelClass: Trash },
            providesTags: ['trash'],
        }),
        deleteTrash: builder.mutation({
            query: (payload) => ({
                url: `/trashes/${payload.id}`,
                method: 'DELETE',
                body: payload,
            }),
            invalidatesTags: ['trash'],
        }),
        restoreTrash: builder.mutation({
            query: (payload) => ({
                url: `/trashes/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['trash'],
        }),
        // Backend Error Logs
        getBackendErrorLogs: builder.query({
            query: (params) => ({
                url: `/backend_error_logs`,
                params,
                method: 'GET',
            }),
            providesTags: ['backend_error_logs'],
            extraOptions: { modelClass: BackendErrorLogs },
        }),
        getBackendErrorLogCategories: builder.query({
            query: () => ({
                url: '/backend_error_logs/category_list',
                method: 'GET',
            }),
            providesTags: ['backend_error_logs'],
        }),
        // Content Topic Briefings
        getContentTopicBriefings: builder.query({
            query: (params) => `/sites/${params.siteId}/content_topic_briefings`,
            providesTags: ['content_topic_briefing'],
            extraOptions: { modelClass: ContentTopicBriefing },
        }),
        getContentTopicBriefing: builder.query({
            query: (params) => `/sites/${params.siteId}/content_topic_briefings/${params.id}`,
            providesTags: ['content_topic_briefing'],
            extraOptions: { modelClass: ContentTopicBriefing },
        }),
        createContentTopicBriefing: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/content_topic_briefings`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['content_topic_briefing'],
            extraOptions: { modelClass: ContentTopicBriefing },
        }),
        updateContentTopicBriefing: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/content_topic_briefings/${payload.id}`,
                method: 'PATCH',
                body: Object.assign({}, payload),
            }),
            invalidatesTags: ['content_topic_briefing'],
            extraOptions: { modelClass: ContentTopicBriefing },
        }),
        deleteContentTopicBriefing: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/content_topic_briefings/${payload.id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['content_topic_briefing'],
        }),
        generateTopicSuggestions: builder.mutation({
            query: (ctb) => ({
                url: `/sites/${ctb.site_id}/content_topic_briefings/${ctb.id}/generate_topic_suggestions`,
                method: 'POST',
            }),
            invalidatesTags: ['content_topic_briefing'],
            extraOptions: { modelClass: ContentTopicBriefing },
        }),
        // Content Topics
        getContentTopics: builder.query({
            query: (params) => `/sites/${params.siteId}/content_topics`,
            providesTags: ['content_topic'],
            extraOptions: { modelClass: ContentTopic },
        }),
        getContentTopic: builder.query({
            query: (params) => `/sites/${params.siteId}/content_topics/${params.id}`,
            providesTags: ['content_topic'],
            extraOptions: { modelClass: ContentTopic },
        }),
        updateContentTopic: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/content_topics/${payload.id}`,
                method: 'PATCH',
                body: Object.assign({}, payload),
            }),
            invalidatesTags: ['content_topic', 'content_topic_briefing'],
            extraOptions: { modelClass: ContentTopic },
        }),
        generateContentFromContentTopic: builder.mutation({
            query: ({ contentTopic, destination, documentName, shareEmails, }) => ({
                url: `/sites/${contentTopic.site_id}/content_topics/${contentTopic.id}/generate_content`,
                method: 'POST',
                body: {
                    destination,
                    document_name: documentName,
                    share_emails: shareEmails,
                },
            }),
            invalidatesTags: ['content_topic', 'content_topic_briefing'],
            extraOptions: { modelClass: ContentTopic },
        }),
        // Page URLs
        getPageURLs: builder.query({
            query: (payload) => ({
                url: `/urls?resource_id=${payload.pageId}&resource_type=Page`,
                method: 'GET',
            }),
            providesTags: ['page_url'],
        }),
        createPageURL: builder.mutation({
            query: (payload) => ({
                url: `/urls`,
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ['page_url'],
        }),
        updatePageURL: builder.mutation({
            query: (payload) => ({
                url: `/urls/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['page_url'],
        }),
        updatePrefix: builder.mutation({
            query: (payload) => ({
                url: `/urls/update_prefix`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['page_url'],
        }),
        setPrimaryURL: builder.mutation({
            query: (payload) => ({
                url: `/urls/set_primary_url`,
                method: 'PUT',
                body: payload,
            }),
            invalidatesTags: ['page_url'],
        }),
        deletePageURL: builder.mutation({
            query: (id) => ({
                url: `/urls/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['page_url'],
        }),
        // Stats
        getDailyTraffic: builder.query({
            query: (params) => ({
                url: `/instances/${params.instance_id}/stats_days/daily_traffic`,
                method: 'GET',
                params,
            }),
        }),
        // Template Fragment
        getTemplateFragments: builder.query({
            query: (params) => `/sites/${params.siteId}/template_fragments?instance_id=${params.instanceId}`,
            providesTags: (result) => [
                'template_fragment',
                ...result.data.map(({ id }) => ({ type: 'template_fragment', id })),
            ],
            extraOptions: { modelClass: TemplateFragment },
        }),
        getTemplateFragmentById: builder.query({
            query: (params) => ({
                url: `/sites/${params.siteId}/template_fragments/${params.id}`,
                method: 'GET',
            }),
            extraOptions: { modelClass: TemplateFragment },
            providesTags: (result, error, args) => [{ type: 'template_fragment', id: args.id }],
        }),
        templateFragmentDelete: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/template_fragments/${payload.id}`,
                method: 'DELETE',
                body: payload,
            }),
            invalidatesTags: ['template_fragment'],
            extraOptions: { modelClass: TemplateFragment },
        }),
        templateFragmentCreate: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/template_fragments`,
                method: 'POST',
                params: payload,
            }),
            extraOptions: { modelClass: TemplateFragment },
            invalidatesTags: (result, error, { id }) => [{ type: 'template_fragment', id }],
        }),
        templateFragmentUpdate: builder.mutation({
            query: (payload) => ({
                url: `/sites/${payload.site_id}/template_fragments/${payload.id}`,
                method: 'PUT',
                body: payload,
            }),
            extraOptions: { modelClass: TemplateFragment },
            invalidatesTags: (result, error, { id }) => [{ type: 'template_fragment', id }],
        }),
    }),
});
// Wrap all endpoints to add the "meta" key to the result
const transformEndpoints = (endpoints) => {
    const transformedEndpoints = {};
    Object.keys(endpoints).forEach((key) => {
        if (key.match(/Mutation$/)) {
            transformedEndpoints[key] = function (...args) {
                var _a, _b;
                const result = endpoints[key](...args);
                return [
                    result[0],
                    Object.assign(Object.assign({}, result[1]), { data: (_a = result[1].data) === null || _a === void 0 ? void 0 : _a.data, meta: (_b = result[1].data) === null || _b === void 0 ? void 0 : _b.meta }),
                ];
            };
        }
        else if (key.match(/^useLazy/)) {
            // TODO: Haven't figured out what to do here. Should try to get the meta data into the result but we don't use
            // many lazy queries so not a big deal.
            transformedEndpoints[key] = endpoints[key];
        }
        else {
            transformedEndpoints[key] = function (...args) {
                var _a, _b;
                const result = endpoints[key](...args);
                return Object.assign(Object.assign({}, result), { data: (_a = result.data) === null || _a === void 0 ? void 0 : _a.data, meta: (_b = result.data) === null || _b === void 0 ? void 0 : _b.meta });
            };
        }
    });
    return transformedEndpoints;
};
const transformedEndpoints = transformEndpoints(apiSlice);
export const { endpoints, 
// Objects
useGetObjectByIdQuery, 
// Instances
useGetInstancesQuery, useGetInstanceByIdQuery, useUpdateInstanceMutation, useGetMasterInstancesQuery, useImportInstanceFromLegacyMutation, useGetInstanceDataQuery, useDuplicateInstanceMutation, 
// Sites
useGetSiteByIdQuery, useGetSitesQuery, useGetAllSitesQuery, useCreateSiteMutation, useDeleteSiteMutation, useUpdateSiteMutation, useSitesLinkingToMeQuery, 
// Geographic Area
useGetGeographicAreaQuery, 
// Business Niches
useGetBusinessNichesQuery, 
// Geographic Code
useLazyGetGeographicCodeQuery, 
// Domains
useGetDomainsQuery, useGetAllDomainsQuery, useUpdateDomainMutation, useDeleteDomainMutation, useAddNewDomainMutation, useMakeDomainPrimaryMutation, useIssueDomainCertificateMutation, 
// Pages
useGetPagesQuery, useGetPageQuery, useGetOrphanPagesQuery, useGetMasterPagesQuery, useGetPageSectionsQuery, useAddNewPageMutation, useDeletePageMutation, usePageUpdateMutation, useDuplicatePageMutation, useGetPageTreeQuery, usePageBulkEditMutation, 
// Email Pages
useGetEmailsQuery, useEmailPageUpdateMutation, useEmailPageDuplicateMutation, useEmailPageCreateMutation, useEmailPageDeleteMutation, useEmailPageProofMutation, 
// Page Versions
useGetPageVersionsQuery, useGetPageVersionQuery, usePageVersionPublishMutation, useGetEmailPageVersionsQuery, usePageVersionCreateMutation, usePageVersionDeleteMutation, usePageVersionUpdateMutation, 
// URLs
useGetURLByIdQuery, 
// Sectors
useGetAllSectorsFromSiteQuery, useGetSectorsQuery, useSectorCreateMutation, useSectorUpdateMutation, useSectorDeleteMutation, useSectorPositionMutation, 
// Sections
useGetSectionsQuery, useGetMasterSectionsQuery, useGetSectionHTMLQuery, useSectionCreateMutation, useGetSectionQuery, useSectionUpdateMutation, useSectionDeleteMutation, useGetColumnsQuery, useSectionPositionUpdateMutation, useGetSectionsByTypeQuery, useDuplicateSectionMutation, 
// Text Styles
useGetTextStyleByIdQuery, useGetTextStylesQuery, useAddNewTextStyleMutation, useUpdateTextStyleMutation, useDeleteTextStyleMutation, 
// Custom Fonts
useGetCustomFontsQuery, 
// Load Media Tool
useGetMediaFilesQuery, 
// Button Styles
useGetButtonStylesQuery, useAddButtonStyleMutation, useGetButtonStyleByIdQuery, useUpdateButtonStyleMutation, useDeleteButtonStyleMutation, 
// Member Email Logs
useGetMemberEmailLogsQuery, 
// Export Members
useExportMembersQuery, 
// Site Briefing
useCreateSiteBriefingMutation, useGetSiteBriefingsQuery, useGetSiteBriefingQuery, useDeleteSiteBriefingMutation, useUpdateSiteBriefingMutation, useAddImageToBriefingMutation, useGenerateInstanceFromSiteBriefingMutation, useGenerateContentFromSiteBriefingMutation, useLazyGenerateQuestionsQuery, 
// Misc
useGetSpellingQuery, 
// Link tool
useLoadLinkToolQuery, 
// Reports
useGetReportConfigQuery, useGetReportDataQuery, 
// Templates
useGetTemplatesQuery, useGetTemplateQuery, useCreateTemplateMutation, useUpdateTemplateMutation, useDeleteTemplateMutation, useDuplicateTemplatesMutation, 
// Custom Tooltips
useGetCustomTooltipsQuery, useCreateCustomTooltipMutation, useUpdateCustomTooltipMutation, 
// Email Series
useGetEmailSeriesQuery, useGetEmailSeriesByIdQuery, useCreateEmailSeriesMutation, useDeleteEmailSeriesMutation, useUpdateEmailSeriesMutation, useEmailSeriesSubscribeMemberMutation, 
// Custom Code
useGetCustomCodesQuery, useGetCustomCodeQuery, useCreateCustomCodeMutation, useUpdateCustomCodeMutation, useDeleteCustomCodeMutation, 
// Database fields
useGetDatabaseFieldsQuery, useGetDatabaseFieldQuery, useCreateDatabaseFieldMutation, useUpdateDatabaseFieldMutation, useDeleteDatabaseFieldMutation, usePositionDatabaseFieldMutation, useGetDatabaseSearchFieldsQuery, 
// Component Categories
useGetComponentCategoriesQuery, useGetComponentCategoryQuery, useUpdateComponentCategoryMutation, useCreateComponentCategoryMutation, useDeleteComponentCategoryMutation, 
// Components
useGetComponentsQuery, useGetComponentQuery, useDuplicateComponentMutation, useDeleteComponentMutation, useCreateComponentMutation, useUpdateComponentMutation, 
// Page Data
useGetPageDataQuery, 
// Admin Roles
useGetRolesQuery, 
// Users
useGetUserQuery, useGetUsersQuery, useCreateUserMutation, useUpdateUserMutation, useDeleteUserMutation, 
// Current User Profile
useLoginUserEmailMutation, useLoginUserGoogleMutation, useGetUserProfileQuery, useLogoutUserMutation, 
// TextBlock
useGetTextBlockHistoryQuery, 
// Recent Items
useGetRecentItemsQuery, useUpdateRecentItemsMutation, 
// CTAs
useGetChatTranscriptQuery, useGetCtaLogsQuery, useGetCtaLogQuery, useDeleteCtaLogMutation, useMarkSpamCtaLogMutation, 
// Saved Search
useGetSavedSearchesQuery, useGetSavedSearchQuery, useCreateSavedSearchMutation, useDeleteSavedSearchesMutation, 
// Members
useGetMembersQuery, useGetMemberQuery, useGetMemberSearchByIdQuery, useDeleteMemberMutation, useDeleteSearchMemberMutation, useMemberSearchCreateMutation, useMemberSearchUpdateMutation, useCreateMemberMutation, useUpdateMemberMutation, 
// Style Override
useGetStyleOverridesQuery, useCreateStyleOverrideMutation, useUpdateStyleOverrideMutation, useDeleteStyleOverrideMutation, 
// Automated Reports
useAutomatedReportsQuery, useRunAutomatedReportMutation, 
// Trash
useGetTrashesQuery, useDeleteTrashMutation, useRestoreTrashMutation, 
// Backend Error Logs
useGetBackendErrorLogsQuery, useGetBackendErrorLogCategoriesQuery, 
// Content Topic Briefings
useGetContentTopicBriefingsQuery, useGetContentTopicBriefingQuery, useCreateContentTopicBriefingMutation, useUpdateContentTopicBriefingMutation, useDeleteContentTopicBriefingMutation, useGenerateTopicSuggestionsMutation, 
// Content Topics
useGetContentTopicsQuery, useGetContentTopicQuery, useUpdateContentTopicMutation, useGenerateContentFromContentTopicMutation, 
// Page URLs
useGetPageURLsQuery, useCreatePageURLMutation, useUpdatePageURLMutation, useDeletePageURLMutation, useSetPrimaryURLMutation, useUpdatePrefixMutation, 
// Stats
useGetDailyTrafficQuery, 
// Template Fragment
useGetTemplateFragmentsQuery, useGetTemplateFragmentByIdQuery, useTemplateFragmentDeleteMutation, useTemplateFragmentCreateMutation, useTemplateFragmentUpdateMutation, } = transformedEndpoints;
const pageDataElement = document.getElementById('pageData');
const pageData = pageDataElement ? JSON.parse(pageDataElement.textContent) : {};
export const pageDataSlice = createSlice({
    name: 'page_data',
    initialState: pageData,
    reducers: {
        setPageData: (state, action) => state = Object.assign(Object.assign({}, state), action.payload),
    },
});
export const { setPageData } = pageDataSlice.actions;
const instanceInitialState = {
    sectionsData: [],
    instancesList: [],
    instance: null,
};
export const instanceSlice = createSlice({
    name: 'instance',
    initialState: instanceInitialState,
    reducers: {
        setInstance: (state, action) => state = Object.assign(Object.assign({}, state), { [action.payload.key]: action.payload.data }),
        updateInstance: (state, action) => {
            const objIndex = state.instancesList.findIndex((obj) => obj.id === action.payload.id);
            if (objIndex !== -1) {
                state.instancesList[objIndex] = action.payload.data;
            }
        },
    },
});
export const { setInstance, updateInstance } = instanceSlice.actions;
export const refetchPageData = () => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const store = createStore();
    const { pageData: { page, page_version: pageVersion, site } } = store.getState();
    const res = yield PageVersionAPI.refetchPageData({ site_id: site === null || site === void 0 ? void 0 : site.id, page_id: page === null || page === void 0 ? void 0 : page.id, id: pageVersion === null || pageVersion === void 0 ? void 0 : pageVersion.id });
    if (undefined !== res) {
        store.dispatch(setPageData((_a = res === null || res === void 0 ? void 0 : res.data) === null || _a === void 0 ? void 0 : _a.data));
    }
});
