import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useAppSelector } from '../../rootStore';
const starTextHolder = 'Select a rating';
const StarsRating = ({ onHandleClickStar, textHolder, fontSize, rating }) => {
    const site = useAppSelector((state) => state.pageData.site || {});
    const { review_threshold = 0, review_star_labels } = site;
    const starText = document.getElementsByClassName('review-text-holder');
    const starRate = rating !== undefined;
    const onMouseEnter = (e) => {
        if (e.currentTarget.dataset.text) {
            starText[0].innerHTML = e.currentTarget.dataset.text;
        }
    };
    const onMouseLeave = () => {
        starText[0].innerHTML = starTextHolder;
    };
    const returnStars = () => ([...Array(5)].map((e, i) => {
        const index = i + 1;
        const sentiment = index >= review_threshold ? 'positive' : 'negative';
        return (_jsx("span", { className: `star-rating__ico ${starRate && i < rating ? 'checked' : ''} ${starRate ? 'pe-none' : ''}`, "data-sentiment": sentiment, style: { fontSize: fontSize || 'inherit' }, "data-text": review_star_labels === null || review_star_labels === void 0 ? void 0 : review_star_labels[i], title: `${index} out of 5 stars`, onClick: () => {
                if (onHandleClickStar !== undefined) {
                    onHandleClickStar(sentiment);
                }
            }, onMouseEnter: (e) => onMouseEnter(e), onMouseLeave: () => onMouseLeave(), children: _jsx("label", { className: "fas fa-star", htmlFor: `star-rating-${index}`, "data-text": review_star_labels === null || review_star_labels === void 0 ? void 0 : review_star_labels[i] }) }, `rating-${index}`));
    }).reverse());
    return (_jsxs(_Fragment, { children: [returnStars(), textHolder && _jsx("label", { className: "review-text-holder d-block", children: starTextHolder })] }));
};
export default StarsRating;
