import Base from './Base';
import { startCase } from 'lodash-es';
class SubTopic {
}
class Question {
}
class ContentTopic extends Base {
    constructor(props) {
        super(props);
    }
    typeText() {
        if (this.type === 'product_service') {
            return 'Product/Service';
        }
        return startCase(this.type);
    }
    percentComplete() {
        var _a;
        let total = 0;
        let complete = 0;
        if (this.type === 'article') {
            (_a = this.sub_topics) === null || _a === void 0 ? void 0 : _a.forEach((subTopic) => {
                var _a, _b;
                total += ((_a = subTopic.questions) === null || _a === void 0 ? void 0 : _a.length) || 0;
                (_b = subTopic.questions) === null || _b === void 0 ? void 0 : _b.forEach((question) => {
                    if (question.answer) {
                        complete += 1;
                    }
                });
            });
        }
        else {
            // complete = 0;
        }
        return total === 0 ? 0 : Math.round((complete / total) * 100);
    }
}
export default ContentTopic;
