/* eslint-disable default-param-last */
import { XERO_CONNECTION } from '../../actions';

const INITIAL_STATE = {
  xeroConnection: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case XERO_CONNECTION:
    return { xeroConnection: action.payload};
  default: return { ...state };
  }
};
