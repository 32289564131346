/* eslint-disable default-param-last */
import {
  GET_TRASH_LIST, GET_RSTORE_OBJ, SET_ERROR, SHOW_REPLACE_SKIP_POPUP, GET_ACTIONMESSAGE,
} from '../../../../redux/actions';

const INITIAL_STATE = {
  error: null,
  trashData: [],
  singleTrashData: [],
  showReplacePopup: false,
  restoreData: {},
  actionMessage: null,
  trashPagination: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TRASH_LIST:
      return {
        ...state,
        trashData: action.payload.trashes,
        trashPagination: action.payload.pagination,
        showReplacePopup: false,
        actionMessage: null,
      };
    case GET_ACTIONMESSAGE:
      return { ...state, actionMessage: action.payload };
    case GET_RSTORE_OBJ: {
      const deleting = { ...state };
      const getUpdateData = deleting.trashData.filter((ele) => ele.id !== action.payload.id);
      return {
        ...state,
        trashData: getUpdateData,
        showReplacePopup: false,
        restoreData: {},
        actionMessage: null,
      };
    }
    case SHOW_REPLACE_SKIP_POPUP:
      return {
        ...state,
        showReplacePopup: action.showPopup,
        restoreData: action.payload,
        actionMessage: null,
      };
    case SET_ERROR:
      return { ...state, error: action.payload, actionMessage: null };
    default: return state;
  }
};
