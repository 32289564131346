import client, { baseURL } from '../../../api';

export default {
  index: (params) => client.get('/components', { params }),
  show: (id) => client.get(`/components/${id}`),
  create: (params) => client.post('/components/', params),
  update: (params) => client.put(`/components/${params.id}`, params),
  delete: (params) => client.delete(`/components/${params.id}`, {params}),
  // Returns URL, not actual request
  preview: (params) => `${baseURL}/components/${params.id}/preview?unique_id=${params.uniqueId}&site_id=${params.siteId}&section_id=${params.section?.id}&scss_vars=${params.section.scss_vars ? encodeURIComponent(JSON.stringify(params.section.scss_vars)) : ''}&html_vars=${params.section.html_vars ? encodeURIComponent(JSON.stringify(params.section.html_vars)) : ''}`,
  duplicate: (id) => client.post(`/components/${id}/duplicate`, id),
};
