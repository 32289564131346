/* eslint-disable default-param-last */
import {
  SET_STYLE_OVERRIDE,
  DELETE_STYLE_OVERRIDE,
  CREATE_STYLE_OVERRIDE,
  UPDATE_STYLE_OVERRIDE_DATA,
} from '../../actions';

const INITIAL_STATE = {
  styleOverride: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case SET_STYLE_OVERRIDE:
    return { ...state, styleOverride: action.payload };
  case UPDATE_STYLE_OVERRIDE_DATA:
    return { ...state, styleOverride: action.payload };
  case DELETE_STYLE_OVERRIDE:
    return {
      ...state,
      styleOverride: state.styleOverride.filter((ele) => ele.id !== action.payload.id),
    };
  case CREATE_STYLE_OVERRIDE:
    return {...state, styleOverride: [...state.styleOverride, action.payload]};
  default: return state;
  }
};
