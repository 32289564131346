import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { sectionObject } from '../../../Utils';
import { filter, compact, isEmpty } from 'lodash-es';
import ComponentAPI from '../../../../admin/dashboard/src/api/component';

function SectionColumn({ section, onClick }) {
  const page = useSelector((state) => state.pageData.page);
  const site = useSelector((state) => state.pageData.site);
  const allSections = useSelector((state) => state.pageData.section);
  const columns = filter(allSections, (s) => s.column_section?.id === section.id);

  const gridTemplateAreas = (device) => {
    const cells = section[`${device}_layout`];

    if (isEmpty(cells)) {
      // This is the default state if the user has not specified a layout for a given device. We just output the
      // sections at 100% wide, on top of each other.
      return `"${columns.map((c) => `s-${c.id} `.repeat(12)).join('" "')}"`;
    }

    const rows = [];

    for (let y = 0; y < 1000; y++) {
      for (let x = 0; x < 12; x++) {
        cells.filter((s) => (
          y >= s.y && y < s.y + s.h && x >= s.x && x < s.x + s.w
        )).forEach((s) => {
          const label = `s-${s.i}`;
          rows[y] ||= [];
          rows[y][x] = label;
        });
      }
    }

    const compactedRows = compact(rows);

    const paddedRows = compactedRows.map((row) => {
      // Pad start of row with blanks
      for (let x = 0; x < row.length; x++) {
        if (typeof row[x] === 'undefined') {
          row[x] = `Blank${Math.round(Math.random() * 100000000)}`;
        }
      }

      // Pad end of row with blanks
      for (let x = row.length; x < 12; x++) {
        row[x] = `Blank${Math.round(Math.random() * 100000000)}`;
      }

      return row;
    });

    return `"${paddedRows.map((r) => r.join(' ')).join('" "')}"`;
  }

  useEffect(() => {
    if (section?.layout !== 'grid') {
      return;
    }

    const stylesheet = `
      .bp-${section.id} {
        grid-template-columns: repeat(12, calc(100% / 12));
      }
      .device-desktop .bp-${section.id} {
        grid-template-areas: ${gridTemplateAreas('desktop')};
      }
      .device-tablet .bp-${section.id} {
        grid-template-areas: ${gridTemplateAreas('tablet')};
      }
      .device-phone .bp-${section.id} {
        grid-template-areas: ${gridTemplateAreas('phone')};
      }
    `;

    let stylesheetEl = document.getElementById(`stylesheet-${section.id}`);
    if (!stylesheetEl) {
      stylesheetEl = document.createElement('style');
      stylesheetEl.type = 'text/css';
      stylesheetEl.id = `stylesheet-${section.id}`;
      document.head.appendChild(stylesheetEl);
    }
    stylesheetEl.innerHTML = stylesheet;
  }, [section, columns]);

  return (
    <>
      <a className="anchor" id={`anchor${section.origin_id}`} />
      <section
        id={`section${section.id}`}
        className={`
          SectionColumn
          ${section.layout === 'grid' ? 'layout-grid' : 'layout-column'}
          bp-${section.id}
          origin${section.origin_id}
          ${section.css_classes?.join(' ')}
        `}
        style={{ display: (section.initial_visibility || wg.env === 'dashboard') ? '' : 'none' }}
      >
        {columns.map((column) => (
          <div
            key={column.id}
            className={`column-wrapper bp-${column.id}`}
            style={{ gridArea: `s-${column.id}` }}
          >
            {wg.env === 'dashboard' && column.type === 'SectionComponent' ? (
              <section className={`SectionComponent bp-${column.id}`}>
                <iframe
                  id={`component-preview-${column.id}`}
                  src={
                    ComponentAPI.preview({
                      uniqueId: `component-preview-${column.id}`,
                      id: column.component_id,
                      siteId: site?.id,
                      section: column,
                    })
                  }
                  className="w-100"
                />
              </section>
            ) : (
              sectionObject(page, column, () => onClick(column))
            )}
          </div>
        ))}
      </section>
    </>
  );
}

export default SectionColumn;
