/* eslint-disable default-param-last */
import { ADD_SECTOR } from '../../actions';

const INIT_STATE = {
  sectorData: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
  case ADD_SECTOR:
    return {
      sectorData: action.payload,
    };
  default: return state;
  }
};
