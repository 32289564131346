import { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash-es';
import { useSelector } from 'react-redux';
import { useGetInstanceByIdQuery, useGetSiteByIdQuery, useGetUserProfileQuery } from '../api/apiSlice';
import { getCookie } from '../../../Utils';
export function useInstance() {
    const [instance, setInstance] = useState(null);
    const params = useParams();
    const getInstanceId = getCookie('INSTANCE_ID');
    const publicInstance = useSelector((state) => { var _a; return (_a = state.pageData) === null || _a === void 0 ? void 0 : _a.instance; });
    const instanceId = (params === null || params === void 0 ? void 0 : params.instanceId) || getInstanceId || (publicInstance === null || publicInstance === void 0 ? void 0 : publicInstance.id) || '';
    const { data } = useGetInstanceByIdQuery(instanceId, { skip: window.wg.env === 'public' || isEmpty(instanceId) });
    useEffect(() => {
        if (window.wg.env === 'public' && publicInstance) {
            setInstance(publicInstance);
        }
        else if (data) {
            setInstance(data);
        }
    }, [publicInstance, data]);
    return instance;
}
export function useSite() {
    const [site, setSite] = useState(null);
    const params = useParams();
    const getSiteId = getCookie('SITE_ID');
    const publicSite = useSelector((state) => { var _a; return (_a = state.pageData) === null || _a === void 0 ? void 0 : _a.site; });
    const siteId = (params === null || params === void 0 ? void 0 : params.siteId) || getSiteId || (publicSite === null || publicSite === void 0 ? void 0 : publicSite.id) || '';
    const { data } = useGetSiteByIdQuery(siteId, { skip: window.wg.env === 'public' || isEmpty(siteId) });
    useEffect(() => {
        if (window.wg.env === 'public' && publicSite) {
            setSite(publicSite);
        }
        else if (data) {
            setSite(data);
        }
    }, [publicSite, data]);
    return site;
}
export function useFireRenderEvent() {
    useEffect(() => {
        document.dispatchEvent(new Event('render'));
    });
}
// This is a handy function which makes all buttons in the component the same width.
export function useButtonsMax() {
    const ref = useRef(null);
    useEffect(() => {
        if (!ref.current) {
            return;
        }
        let maxWidth = 0;
        const buttons = ref.current.querySelectorAll('button');
        if (buttons) {
            buttons.forEach((button) => {
                const width = getComputedStyle(button).width;
                const parsedWidth = parseFloat(width);
                if (parsedWidth > maxWidth) {
                    maxWidth = parsedWidth;
                }
            });
            buttons.forEach((button) => {
                button.style.width = `${maxWidth}px`;
            });
        }
    });
    return ref;
}
export function useUser() {
    const { data } = useGetUserProfileQuery({});
    return data;
}
