import React, { lazy, Suspense } from 'react';
import { toast } from 'react-toastify';
import { createStore } from './rootStore';
import { map, isEmpty, get, maxBy } from 'lodash-es';
import WebFont from 'webfontloader';
import Color from 'color';
import SectionContent from './PublicFacingSite/Sections/Content';
import SectionForm from './PublicFacingSite/Sections/Form';
import SectionMirror from './PublicFacingSite/Sections/Mirror';
import SectionReviewForm from './PublicFacingSite/Sections/ReviewForm';
import SectionImageGallery from './PublicFacingSite/Sections/ImageGallery';
import SectionColumn from './PublicFacingSite/Sections/Column';
import SectionReviewList from './PublicFacingSite/Sections/ReviewList';
import SectionComponent from './PublicFacingSite/Sections/Component';
import macroReplacerString from './PublicFacingSite/macroReplacerString';
import webSafeFontOptions from './dashboard/src/schema/webSafeFontOptions';
import { deviceType } from './dashboard/src/schema/formSection';
import ActionPageInstantContactInterstitial from './PublicFacingSite/Components/ActionPageInstantContactInterstitial';
import ActionPageInstantContactChat from './PublicFacingSite/Components/ActionPageInstantContactChat';

// Lazy-loading these two so that we don't bring admin code into the public-facing bundle.
const SectionContentEmail = lazy(() => import(/* webpackChunkName: "admin" */ './dashboard/src/components/SectionContentEmail'));
const SectionColumnEmail = lazy(() => import(/* webpackChunkName: "admin" */ './dashboard/src/components/SectionColumnEmail'));

const urlStopWords = [
  'am',
  'an',
  'and',
  'as',
  'at',
  'co',
  'com',
  'eg',
  'et',
  'etc',
  'ex',
  'for',
  'i\'d',
  'i\'ll',
  'i\'m',
  'i\'ve',
  'ie',
  'if',
  'in',
  'inc',
  'is',
  'isn\'t',
  'it\'d',
  'it\'ll',
  'it\'s',
  'its',
  'me',
  'nd',
  'no',
  'of',
  'oh',
  'or',
  'rd',
  're',
  'so',
  'th',
  'the',
  'to',
  'un',
];

export const specialFieldsArr = ['Join IP Address', 'Join Country', 'Join Method', 'Join Date', 'Included in import'];

export const sectionObject = (page, section, onClick) => {
  switch (section?.type) {
    case 'SectionContent':
      if (page?.page_type === 'email') {
        return (
          <Suspense fallback={<></>}>
            <SectionContentEmail key={section.id} section={section} onClick={onClick} />
          </Suspense>
        );
      } else {
        return <SectionContent key={section.id} section={section} onClick={onClick} />;
      }
    case 'SectionForm':
      return <SectionForm key={section.id} section={section} onClick={onClick} />;
    case 'SectionColumn':
      if (page?.page_type === 'email') {
        return (
          <Suspense fallback={<></>}>
            <SectionColumnEmail key={section.id} section={section} />
          </Suspense>
        );
      } else {
        return <SectionColumn key={section.id} section={section} onClick={onClick} />;
      }
    case 'SectionMirror':
      return <SectionMirror key={section.id} section={section} onClick={onClick} />;
    case 'SectionReviewForm':
      return <SectionReviewForm key={section.id} section={section} onClick={onClick} />;
    case 'SectionImageGallery':
      return <SectionImageGallery key={section.id} section={section} onClick={onClick} />;
    case 'SectionReviewList':
      return <SectionReviewList key={section.id} section={section} onClick={onClick} />;
    case 'SectionComponent':
      return <SectionComponent key={section.id} section={section} onClick={onClick} />;
  }
};

export const validateEmail = (value) => {
  const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
  if (!reg.test(value)) {
    return false;
  }
  return true;
};

export const displayError = (message, timeOut = 3000) => {
  toast.error(message, { autoClose: timeOut });
};

export const displaySuccess = (message, timeOut = 3000) => {
  toast.success(message, { autoClose: timeOut });
};

export const displayInfo = (message, timeOut = 3000) => {
  toast.info(message, { autoClose: timeOut });
};

// Helper function to be called from inside an iframe to set its height. Only works for internal iframes, ie on the
// same origin as the parent document. It matches based on the URL of the iframe, meaning it will have problems if we
// have two iframes with the same src, but that's unlikely.
export const setIframeHeight = function(src, height) {
  const iframes = Array.from(document.querySelectorAll('iframe')).filter((f) => {
    if (f.src === src) {
      return true;
    }

    // If the iframe's URL has changed (ie by clicking a link inside the iframe) the above won't work because the src
    // attribute of the iframe doesn't change. The below catches this situation.
    if (f.contentDocument?.location?.href === src) {
      return true;
    }

    return false;
  });
  if (iframes.length > 0) {
    const currentHeight = parseInt(iframes[0].style.height || 0, 10);
    if (height > currentHeight)
      iframes[0].style.height = (height + 1) + 'px';
  }
};

export const ucWords = (str) => {
  const arr = str.split(' ');
  for (let i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  return arr.join(' ');
};

export const setCookie = (cName, cValue, expDays, path) => {
  // We have had a few situations where we have tried to set a cookie to the string "undefined". This can happen when
  // we redirect to a URL with an undefined parameter and we get the literal "undefined" in the URL, and then we try to
  // set that as a cookie. I (MP) think we will probably never want to set a cookie to "undefined", and it's probably
  // best to disallow it.
  if (cValue === 'undefined') {
    console.trace();
    throw new Error('Trying to set undefined cookie');
  }
  const date = new Date();
  date.setTime(date.getTime() + (expDays * 24 * 60 * 60 * 1000));
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${cName}=${cValue}; expires=${expires}; path=${path}; SameSite=None; Secure;`;
};

export const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i += 1) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

export const isInViewport = (el) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0
    && rect.left >= 0
    && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
    && rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const removeHighlights = () => {
  [
    ...document.querySelectorAll('.page-edit-highlight'),
    ...document.getElementById('adminRoot')?.shadowRoot?.querySelectorAll('.page-edit-highlight'),
  ].forEach((e) => {
    e.classList.add('page-edit-highlight-removed');
    e.classList.remove('page-edit-highlight');
  });
};

export const highlightElement = (el) => {
  removeHighlights();

  if (isInViewport(el)) {
    el.classList.add('page-edit-highlight');
    setTimeout(removeHighlights, 1000);
  } else {
    const rect = el.getBoundingClientRect();
    let top = (rect.top + window.scrollY) - 100;
    if (top < 0) {
      top = 0;
    }

    const onScroll = () => {
      const atBottom = (window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight;
      if (Math.round(window.scrollY) === Math.round(top) || atBottom) {
        window.removeEventListener('scroll', onScroll);
        el.classList.add('page-edit-highlight');
        setTimeout(removeHighlights, 1000);
      }
    };

    window.addEventListener('scroll', onScroll);
    onScroll();
    window.scrollTo({ top, behavior: 'smooth' });
  }
};

export const replaceLinks = (ref, allLinks) => {
  if (!ref) {
    return;
  }

  const { pageData: { section: allSections, site } } = createStore().getState();

  ref.querySelectorAll('a[href*=links').forEach((a) => {
    a.href.match(/\/links\/[0-9a-f]{24}/g)?.forEach((link) => {
      const linkId = link.match(/[0-9a-f]{24}/)[0];
      const linkObject = allLinks.find((l) => l.id === linkId);
      if (!linkObject) {
        return;
      }

      a.href = macroReplacerString(linkObject.url);

      if (linkObject.type === 'toggle_visibility_of_section') {
        a.classList.add('toggleVisibility');
        const targetSection = allSections.find((s) => s.id === linkObject.section_id);
        if (targetSection) {
          a.dataset.originId = targetSection.origin_id;
        }
      } else if (linkObject.type === 'phone_number' && linkObject.site_phone && !isEmpty(site.displayed_phone_number)) {
        if (a.innerHTML.match(/button-inner/)) {
          // This link is a button, we need to preserve the button and the icons, which use <i> tags.
          var inner = a.querySelector('.button-inner');
          if (inner) {
            inner.innerHTML = site.displayed_phone_number;
          }
        } else if (a.innerHTML.match(/<button/)) {
          // This link is a button but without the button-inner... not sure how this happens, but it does.
          var inner = a.querySelector('button');
          if (inner) {
            inner.innerHTML = site.displayed_phone_number;
          }
        } else {
          a.innerHTML = site.displayed_phone_number;
        }

        if (a.href && a.href.match(/^tel:/)) {
          a.href = 'tel:' + site.displayed_phone_number;
        }
      } else if (linkObject.link_target === '_blank') {
        a.target = '_blank';
      } else if (linkObject.link_target === 'framebox') {
        a.classList.add('showModal');
      }
    });
  });

  return ref;
};

export const generateURL = (str) => {
  const expStr = urlStopWords.join('|');

  const url = str
    .toLowerCase()
    .replace(new RegExp(`\\b(${expStr})\\b`, 'g'), ' ')
    .replace(/^\s+/, '')
    .replace(/\s$/, '')
    .replace(/\s+/g, ' ')
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-'); // strip out multiple hyphens in a row

  // Use only the first three words.
  return url.split('-').slice(0, 3).join('-');
};

export const validateDomain = (value) => {
  if (value.length > 0) {
    if (/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/g.test(value)) {
      return true;
    }
    return 'Enter Valid Domain Name';
  }
  return 'Enter Domain Name';
};

export const validatePhoneNumber = (value) => {
  const reg = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  if (!reg.test(value)) {
    return false;
  }
  return true;
};

export const setPageTitle = (title) => {
  document.title = `Web Genius - ${title}`;
};

export const addGoogleFont = (googleFonts) => {
  googleFonts.map((item) => {
    // Don't add if it's a web-safe font.
    if (webSafeFontOptions.some((f) => f.name === item.fontName)) {
      return null;
    }

    const searchString = `${item.fontName}:${item.fontWeight}`;
    const stylesheets = [...document.styleSheets];
    const condition = stylesheets.filter(
      (sheet) => sheet.href !== null && sheet.href.includes(searchString),
    );
    if (condition.length === 0) {
      WebFont.load({ google: { families: [`${item.fontName}:${item.fontWeight}`] } });
    }
    return null;
  });
};

export const getDomainName = (str) => {
  const reg = /(?:[\w-]+\.)+[\w-]+/g;
  return reg.exec(str);
};

export const selectColorStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'var(--background-color)',
  }),
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? 'var(--foreground-color)' : 'var(--primary-color) !important',
    backgroundColor: state.isSelected ? 'var(--theme-color-1)' : 'var(--foreground-color)',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'var(--primary-color) !important',
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: 9999,
  }),
};

export const getSelectedOption = (options, selectedOption, keyName = 'value') => {
  if (!options) {
    return null;
  }
  // eslint-disable-next-line eqeqeq
  const data = options.filter((item) => (item[keyName] == selectedOption))[0];
  return data || null;
};

export const getArrIndex = (arr, key, value) => arr.findIndex((e) => e[key] === value);

export const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const secondsToHms = (e) => {
  const d = Number(e);
  // const h = Math.floor(d / 3600);
  const m = Math.floor((d % 3600) / 60);
  const s = Math.floor((d % 3600) % 60);

  // const hDisplay = h > 0 ? `${h}h` : "";
  const mDisplay = m > 0 ? `${m}m` : '';
  const sDisplay = s > 0 ? `${s}s` : '';
  return `${mDisplay} ${sDisplay}`;
};

export const getFlagEmoji = (countryCode) => {
  if (typeof countryCode !== 'string') {
    return '';
  }

  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt());
  return String.fromCodePoint(...codePoints);
};

export const removeDuplicateObj = (dataArr) => (
  dataArr.reduce((unique, o) => {
    if (!unique.some((obj) => obj.label === o.label && obj.value === o.value)) {
      unique.push(o);
    }
    return unique;
  }, [])
);

export const getSpecialFieldIdByName = (arr, keyName) => {
  const res = arr.filter((ele) => ele.label === keyName);
  return isEmpty(res) ? null : res[0].value;
};

export const getDataFieldOptions = (data) => {
  const specialField = [];
  const dbField = [];
  map(data, (option) => {
    const fieldName = option.attributes.field_name;
    if (specialFieldsArr.includes(fieldName)) {
      specialField.push({ label: fieldName, value: option.id });
    } else {
      dbField.push({ label: fieldName, value: option.id });
    }
  });
  return [...dbField, ...specialField];
};

export const getPageType = (page) => (
  (page?.page_type === 'normal' || page?.page_type === 'master') ? 'web' : page?.page_type
);

export const colourPalettePreset = (data, presetColor) => {
  if (Array.isArray(data)) {
    map(data, (item, rowIndex) => {
      presetColor.forEach((element) => {
        if (item.setting_value === element.hex) {
          data[rowIndex].setting_value = `-${element.id}`;
        }
      });
    });
    return data;
  }

  const getHex = presetColor.filter((color) => color.hex === data);
  return isEmpty(getHex) ? data : `-${getHex[0].id}`;
};

export const rgbaToHex = (rgb, isEmailPage) => {
  const outParts = [
    rgb.r.toString(16),
    rgb.g.toString(16),
    rgb.b.toString(16),
  ];

  if (!isEmailPage) {
    outParts.push(Math.round(rgb.a * 255).toString(16).substring(0, 2));
  }

  outParts.forEach((part, i) => {
    if (part.length === 1) {
      outParts[i] = `0${part}`;
    }
  });

  return (`#${outParts.join('')}`);
};

export const selectBoxOptions = (arr, labelKey, valueKey) => map(arr, (option) => ({
  ...option,
  label: labelKey ? option[labelKey] : option,
  value: valueKey ? option[valueKey] : option,
}));

export const getUnitType = (value) => {
  if (!value) {
    return '';
  }

  let len = value.length;
  if (!len)
    return 'px';

  let i = len;
  while (i--)
    if (!isNaN(value[i]))
      return value.slice(i + 1, len) || 'px';

  return 'px';
};

export const getFontSize = (data) => {
  const allSize = get(data, 'break_point.all_styles.font-size');
  if (!isEmpty(allSize)) {
    return allSize;
  }

  const desktopSize = get(data, 'break_point.desktop_styles.font-size');
  if (!isEmpty(desktopSize)) {
    return desktopSize;
  }

  return null;
};

export const formatBytes = (bytes, b) => {
  if (bytes === 0) {
    return '-';
  }
  const c = 1024;
  const d = b || 2;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const index = Math.floor(Math.log(bytes) / Math.log(c));
  return `${parseFloat((bytes / c ** index).toFixed(d))} ${sizes[index]}`;
};

export const getMaxValueByKey = (arr, key) => {
  if (isEmpty(arr)) {
    return 1;
  }

  const max = maxBy(arr, (s) => s[key]);

  if (max) {
    return max[key] + 1;
  }

  return null;
};

export const setTheme = (theme) => {
  const cl = document.body.classList;
  if (cl.contains(`theme-${theme}`)) {
    return;
  }

  cl.forEach((c) => {
    if (c.match(/theme-/)) {
      cl.remove(c);
    }
  });

  cl.add(`theme-${theme}`);
};

export const getContrast = (c1, c2) => {
  const parsed1 = Color(c1);
  const parsed2 = Color(c2);

  if (!parsed1 || !parsed2) {
    return null;
  }

  const object1 = parsed1.object();
  const object2 = parsed2.object();

  const g1 = [Math.pow(object1.r / 255, 2.2), Math.pow(object1.g / 255, 2.2), Math.pow(object1.b / 255, 2.2)];
  const g2 = [Math.pow(object2.r / 255, 2.2), Math.pow(object2.g / 255, 2.2), Math.pow(object2.b / 255, 2.2)];

  const luminance1 = (0.2126 * g1[0] + 0.7152 * g1[1] + 0.0722 * g1[2]);
  const luminance2 = (0.2126 * g2[0] + 0.7152 * g2[1] + 0.0722 * g2[2]);

  return luminance1 > luminance2 ? ((luminance1 + 0.05) / (luminance2 + 0.05)) : ((luminance2 + 0.05) / (luminance1 + 0.05));
};

export const getOppositeColor = (color) => {
  const parsed = Color(color);
  if (!parsed) {
    return color;
  }
  const object = parsed.object();
  return `rgb(${255 - object.r}, ${255 - object.g}, ${255 - object.b})`;
};

export const getInstanceByIdFromListing = (id) => {
  const store = createStore();
  const { adminStore: { instanceReducer } } = store.getState();
  if (instanceReducer) {
    // eslint-disable-next-line eqeqeq
    return instanceReducer?.instancesList.filter((instance) => instance.id == id)[0];
  }
  return {};
};

export const getDeviceType = (e) => {
  switch (e) {
    case 'desktop':
      return deviceType.DESKTOP;
    case 'tablet':
      return deviceType.TABLET;
    case 'phone':
      return deviceType.PHONE;
    default: return deviceType.DESKTOP;
  }
};

// Get the component for a given action string. Only for the actions which are rendered via React, not server-side actions.
export const getActionComponentFromString = (action) => (
  {
    InstantContactInterstitial: ActionPageInstantContactInterstitial,
    InstantContactChat: ActionPageInstantContactChat,
  }[action]
);

export const updateStylesheet = (params = {}) => {
  const store = createStore();
  const { pageData: { admin_domain } } = store.getState();
  let el = document.getElementById('dashboard-style-link');
  if (el === null) {
    el = document.createElement('link');
    el.id = 'dashboard-style-link';
    el.rel = 'stylesheet';
  }
  const { siteId, pageId, pageVersionId } = params;

  // We want to allow updating the existing stylesheet even if we don't have the parameters available. If we have the
  // parameters then we use them, otherwise we just update the existing stylesheet URL with a new random parameter to
  // force a refresh.

  const adminDomain = admin_domain ? `https://${admin_domain}/api/v1` : '/api/v1';
  if (siteId && pageId && pageVersionId) {
    el.href = `${adminDomain}/sites/${siteId}/pages/${pageId}/page_versions/${pageVersionId}/stylesheet?prefix=true&rand=${crypto.randomUUID()}`;
  } else {
    el.href = el.href.replace(/rand=.*/, `rand=${crypto.randomUUID()}`);
  }

  document.head.appendChild(el);
};

export const removeStylesheet = () => {
  let el = document.getElementById('dashboard-style-link');
  if (el === null) {
    return;
  }
  el.remove();
};
