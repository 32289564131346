import client from '../../../api';

export default {
  /* Database Fields */
  databaseFieldCreate: (instanceId) => client.post(`instances/${instanceId}/database_fields`),
  databaseFieldListing: (instance_id) => client.get(`instances/${instance_id}/database_fields`),
  databaseFieldGetByFieldId: (params) => client.get(
    `instances/${params.instance_id}/database_fields/${params.field_id}`,
  ),
  databaseFieldDelete: (params) => client.delete(`instances/${params.instance_id}/database_fields/${params.field_id}`),
  databaseFieldUpdate: (params) => client.put(`instances/${params.instance_id}/database_fields/${params.id}`, params),
  databaseFieldPositionUpdate: (params) => client.put(
    `instances/${params.instance_id}/database_fields/update_position`,
    params,
  ),
  getSavedSearchData: (searchId) => client.get(`/searches/${searchId}`),

  /* Saved Searches */
  saveSearchCreate: (params) => client.put(`/searches/${params.id}`, params),
  saveSearchesGet: (params) => client.get(`/instances/${params.instance_id}/searches`),
  savedSearchDelete: (id) => client.delete(`/searches/${id}`),
};
