import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import LowRatingFormHTML from './LowRatingFormHTML';
import HighRatingFormHTML from './HighRatingFormHTML';
import StarsRating from '../../Components/StarsRating';
import { useAppSelector } from '../../../rootStore';
const SectionReviewForm = ({ section, onClick }) => {
    var _a;
    const site = useAppSelector((state) => state.pageData.site);
    const [lowRatingFormModal, setLowRatingFormModal] = useState(false);
    const [highRatingFormModal, setHighRatingFormModal] = useState(false);
    const onHandleClickStar = (sentiment) => {
        if (sentiment === 'positive') {
            setLowRatingFormModal(false);
            setHighRatingFormModal(!highRatingFormModal);
        }
        else {
            setHighRatingFormModal(false);
            setLowRatingFormModal(!lowRatingFormModal);
        }
    };
    const renderHTML = () => {
        if (site && (site === null || site === void 0 ? void 0 : site.review_binary)) {
            return (_jsxs("div", { className: "review-binary-options", children: [_jsx("img", { src: "/assets/review-icon-dislike.png", "data-sentiment": "negative", onClick: () => onHandleClickStar('negative') }), _jsx("img", { src: "/assets/review-icon-like.png", "data-sentiment": "positive", onClick: () => onHandleClickStar('positive') })] }));
        }
        return (_jsx("div", { className: "star-rating", children: _jsx("div", { className: "star-rating__wrap", children: _jsx(StarsRating, { onHandleClickStar: onHandleClickStar, textHolder: true }) }) }));
    };
    return (_jsxs(_Fragment, { children: [_jsx("a", { className: "anchor", id: `anchor${section.origin_id}` }), _jsx("section", { id: `section${section.id}`, className: `SectionReviewForm bp-${section.id} origin${section.origin_id} ${(_a = section.css_classes) === null || _a === void 0 ? void 0 : _a.join(' ')}`, style: { display: (section.initial_visibility || window.wg.env === 'dashboard') ? '' : 'none' }, onClick: () => onClick(section), children: renderHTML() }), lowRatingFormModal && (_jsx(LowRatingFormHTML, { modal: lowRatingFormModal, toggle: setLowRatingFormModal, section: section })), highRatingFormModal && (_jsx(HighRatingFormHTML, { modal: highRatingFormModal, toggle: setHighRatingFormModal }))] }));
};
export default SectionReviewForm;
