/* eslint-disable default-param-last */
import { SET_AUTO_REPORT } from '../actions';

const INITIAL_STATE = {
  autoReports: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case SET_AUTO_REPORT:
    return { ...state, autoReports: action.payload };
  default: return state;
  }
};
