import { configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { isArray } from 'lodash-es';
import { combineReducers } from 'redux';
import reducers from '../dashboard/src/redux/reducers';
import { apiSlice, pageDataSlice } from '../dashboard/src/api/apiSlice';
import { displayError } from '../Utils';
import { useDispatch, useSelector } from 'react-redux';
const rtkQueryErrorLogger = () => (next) => (action) => {
    if (isRejectedWithValue(action)) {
        let message = action.payload.data.messages;
        if (isArray(action.payload.data.data)) {
            action.payload.data.data.forEach((m) => message += `\n${m}`);
        }
        displayError(message);
    }
    return next(action);
};
const rootReducer = combineReducers({
    adminStore: reducers,
    pageData: pageDataSlice.reducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
});
const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => (getDefaultMiddleware({
        serializableCheck: false,
    }).concat(thunk, apiSlice.middleware, rtkQueryErrorLogger)),
});
export const useAppDispatch = () => useDispatch();
export const useAppSelector = useSelector;
export const createStore = () => {
    return store;
};
