/* eslint-disable default-param-last */
import { SET_COMPONENT_CATEGORIES, SET_COMPONENT_CATEGORY, UPDATE_COMPONENT_CATEGORY, CREATE_COMPONENT_CATEGORY, DELETE_COMPONENT_CATEGORY } from '../../actions';

const INITIAL_STATE = {
  componentCategories: [],
  componentCategory: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case SET_COMPONENT_CATEGORIES:
    return { ...state, componentCategories: action.payload };
  case SET_COMPONENT_CATEGORY:
    return { ...state, componentCategory: action.payload };
  case UPDATE_COMPONENT_CATEGORY:
    return { ...state, componentCategory: action.payload };
  case CREATE_COMPONENT_CATEGORY:
    return { ...state, componentCategories: [...state.componentCategories, action.payload] };
  case DELETE_COMPONENT_CATEGORY: {
    const res = state.componentCategories.filter((c) => c.id !== action.payload);
    return { ...state, componentCategories: res };
  }
  default: return state;
  }
};
