import axios from 'axios';
import { displayError } from '../Utils';
import { spinCountInc, spinCountDec } from '../dashboard/src/redux/actions';
import { createStore } from '../rootStore';
export const baseDomain = '';
export const baseURL = `${baseDomain}/api/v1`;
const api = axios.create({ withCredentials: true });
const isErrorResponseData = (data) => {
    return typeof data === 'object' && data !== null;
};
const errorHandler = (error) => {
    var _a;
    const store = createStore();
    if (error.response) {
        const responseData = error.response.data;
        if (isErrorResponseData(responseData)) {
            if (typeof responseData.messages === 'object' && ((_a = responseData.messages) === null || _a === void 0 ? void 0 : _a.url)) {
                displayError(`URL ${responseData.messages.url[0]}`);
            }
            else {
                const messages = [
                    typeof responseData.messages === 'string'
                        ? responseData.messages
                        : error.message
                ];
                if (responseData.data) {
                    Object.keys(responseData.data).forEach((type) => messages.push(responseData.data[type]));
                }
                displayError(messages[0] || error.message);
            }
        }
        else {
            displayError(error.message);
        }
    }
    store.dispatch(spinCountDec());
};
const successHandler = (response) => {
    const store = createStore();
    const { config } = response;
    if (config.disableSpinner !== true) {
        store.dispatch(spinCountDec());
    }
    return response;
};
api.interceptors.request.use((config) => {
    const store = createStore();
    if (config.disableSpinner !== true) {
        store.dispatch(spinCountInc());
    }
    return config;
}, (error) => Promise.reject(error));
api.interceptors.response.use((response) => successHandler(response), (error) => errorHandler(error));
// Set the baseURL dynamically so that we can use the admin domain from the redux store.
api.interceptors.request.use((config) => {
    const store = createStore();
    const { pageData: { admin_domain } } = store.getState();
    config.baseURL = admin_domain ? `https://${admin_domain}/api/v1` : '/api/v1';
    return config;
}, (error) => Promise.reject(error));
export default api;
