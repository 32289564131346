import Base from './Base';
export default class Reports extends Base {
    constructor(props) {
        super(props);
        this.rows = props.rows;
        this.rowCount = props.rowCount;
    }
}
export class ReportConfig extends Base {
    constructor(props) {
        super(props);
        Object.assign(this, props);
    }
}
