import { isArray } from 'lodash-es';
import Base from './Base';
class User extends Base {
    constructor(props) {
        super(props);
        this.id = String(props.id);
        this.email = String(props.email);
        this.modules = props.modules;
        this.role = props.role;
        this.feature_overrides = props.feature_overrides;
    }
    hasModule(module) {
        var _a, _b;
        return (_b = (_a = this.modules) === null || _a === void 0 ? void 0 : _a.includes(module)) !== null && _b !== void 0 ? _b : false;
    }
    hasFeature(feature) {
        var _a, _b, _c, _d, _e;
        if (((_a = this.role) === null || _a === void 0 ? void 0 : _a.name) === 'System Administrator') {
            return true;
        }
        if (isArray(feature)) {
            // feature can be an array, if it is then we render the component if the user has ANY of the requested features enabled.
            return feature.some((f) => this.hasFeature(f));
        }
        if (typeof feature === 'string') {
            if ((_b = this.feature_overrides) === null || _b === void 0 ? void 0 : _b.includes(feature)) {
                return true;
            }
            return (_e = (_d = (_c = this.role) === null || _c === void 0 ? void 0 : _c.features) === null || _d === void 0 ? void 0 : _d.includes(feature)) !== null && _e !== void 0 ? _e : false;
        }
        return false;
    }
}
export default User;
