import React, { Fragment, useEffect, useCallback, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import { useSelector, Provider } from 'react-redux';
import { filter, isEmpty, uniqueId } from 'lodash-es';
import TextBlockMedia from '../../TextBlockMedia';
import { replaceLinks, sectionObject } from '../../../Utils';
import macroReplacerNode from '../../macroReplacerNode';
import macroReplacerString from '../../macroReplacerString';
import { createStore } from '../../../rootStore';

// We need to add linebreaks inside the dom element manually, so we need the break element as a string.
const lineBreakText = (b) => {
  switch (b) {
    case 'single_line':
      return '<br>';
    case 'double_line':
      return '<br><br>';
    case 'clear_left':
      return '<br class=clear-left>';
    case 'clear_right':
      return '<br class=clear-right>';
  }
  return '';
};

// And we also need to add linebreaks when there is no tag, and for this we need the break element as a component.
const lineBreakElement = (b) => {
  switch (b) {
    case 'single_line':
      return <br />;
    case 'double_line':
      return (
        <>
          <br />
          <br />
        </>
      );
    case 'clear_left':
      return <br className="clear-left" />;
    case 'clear_right':
      return <br className="clear-right" />;
  }
  return null;
};

// This is a container to support section macros inside textblocks. It just returns whatever children are inside it,
// and when it has finished rendering it calls macroReplacerNode() on the node that is passed to it via the node prop.
const MacroContainer = ({ node, children }) => {
  useEffect(() => macroReplacerNode(node), []);

  return children;
};

function SectionContentNewModel({ section, onClick }) {
  const page = useSelector((state) => state.pageData.page);
  const allTextblocks = useSelector((state) => state.pageData.text_block);
  const allTextStyles = useSelector((state) => state.pageData.text_style);
  const allLinks = useSelector((state) => state.pageData.link);
  const allSections = useSelector((state) => state.pageData.section);
  const allButtonStyles = useSelector((state) => state.pageData.button_style);
  const refs = {};
  const textBlocks = filter(allTextblocks, (t) => t.section_content_id === section.id && !t.is_offline);
  const sectionRegex = /\(section:([0-9a-f]{24})\)/ig;

  // React requires us to have the same amount of hooks on each render, so we can't just create a ref for each
  // textblock because if we add a textblock then the number of hooks will change. So, we create a bunch of empty refs
  // and just use the ones that we need to.
  for (let i = 0; i < 200; i++) {
    refs[i] = useRef(null);
  }

  // We need to remove the "style" attribute from all buttons set via the CMS. This is because we want the styles from
  // the Button Styles page in admin to override whatever is set in the textblock.
  const stripButtonStyles = (node) => {
    node.querySelectorAll('a[data-button-style-id]').forEach((a) => {
      const styleId = a.dataset.buttonStyleId;
      if (!styleId) {
        return;
      }

      const style = allButtonStyles?.find((s) => s.id === styleId);
      if (!style) {
        return;
      }

      a.querySelectorAll('button').forEach((b) => b.removeAttribute('style'));
      a.querySelectorAll('button > i').forEach((i) => i.removeAttribute('style'));
    });
  };

  useEffect(() => {
    textBlocks.forEach((textBlock, i) => {
      const ref = refs[i];
      if (!ref?.current) {
        return;
      }

      ref.current.innerHTML = textBlock.block_text + lineBreakText(textBlock.line_break);

      // <script> tags that are injected into the DOM are basically ignored, so we need to run them manually. We don't
      // want to run them more than once, so we keep track of which sections we have processed.
      const scriptTags = ref.current.querySelectorAll('script');
      if (scriptTags.length > 0) {
        if (typeof window.scriptsRun === 'undefined') {
          window.scriptsRun = [];
        }
        if (!window.scriptsRun.includes(section.id)) {
          window.scriptsRun.push(section.id);

          scriptTags.forEach((s) => {
            if (s.src) {
              const newS = document.createElement('script');
              newS.src = s.src;
              document.head.appendChild(newS);
            } else {
              try {
                eval(s.innerHTML);
              } catch (e) {
                console.log('Error when evaluating raw textblock:', e);
              }
            }
          });
        }
      }

      if (ref.current.innerHTML.match(sectionRegex)) {
        // Process section macros in textblocks. This is a bit complicated because we need to render a React component
        // (the section) inside the textblock text, which is raw HTML.

        // First make an array of all text nodes inside the textblock.
        let node;
        const allNodes = [node];
        const walker = document.createTreeWalker(ref.current, NodeFilter.SHOW_TEXT);
        while (node = walker.nextNode()) {
          allNodes.push(node);
        }

        // Then loop through each node
        allNodes.forEach((node) => {
          if (!node) {
            return;
          }

          const matches = [...(node.nodeType === 1 ? node.innerHTML : node.textContent).matchAll(sectionRegex)];
          matches?.forEach((match) => {
            const sectionId = match[1];

            const section = allSections.find((s) => s.id === sectionId || s.origin_id === sectionId);
            if (!section) {
              return;
            }

            const reactNode = document.createElement('div');
            const newNode = document.createElement('div');
            const root = createRoot(reactNode);
            root.render(
              <MacroContainer node={newNode}>
                <Provider store={createStore()}>
                  {sectionObject(page, section, onClick)}
                </Provider>
              </MacroContainer>
            );

            const newId = `section${(Math.random() * 9999999999).toFixed()}`;
            newNode.innerHTML = (node.nodeType === 1 ? node.innerHTML : node.textContent).replace(match[0], `<div id="${newId}"></div>`);
            newNode.querySelector(`#${newId}`).replaceWith(reactNode);
            node.replaceWith(newNode);
          });
        });
      }

      replaceLinks(ref.current, allLinks);
      stripButtonStyles(ref.current);
      macroReplacerNode(ref.current);
    });
  });

  const textBlockElements = textBlocks.map((textBlock, i) => {
    const textStyle = textBlock.text_style_id ? allTextStyles.find((s) => s.id === textBlock.text_style_id) : null;
    const Tag = textStyle ? textStyle.tag : 'span';
    const media = textBlock.media ? <TextBlockMedia media={textBlock.media} /> : null;

    // Both the old platform and the Ruby version always add the line break, regardless of whether there is any text in
    // the textblock or not. But they add it inside the tag if there is text, and without the tag if there is no text.
    // If there is text, the linebreak is added above in the useEffect() call, and if there is no text we add it here
    // with the call to lineBreakElement().
    return (
      <Fragment key={textBlock.id}>
        {media}
        {isEmpty(textBlock.block_text) && lineBreakElement(textBlock.line_break)}
        {textBlock.type === 'raw' && (
          <span
            className="tb raw-tb"
            ref={refs[i]}
          />
        )}
        {textBlock.type !== 'raw' && !isEmpty(textBlock.block_text) && (
          <Tag
            className={`tb style-${textBlock.text_style_id}`}
            ref={refs[i]}
          />
        )}
      </Fragment>
    );
  });

  return (
    <>
      <a className="anchor" id={`anchor${section.origin_id}`} />
      <section
        id={`section${section.id}`}
        className={`SectionContent bp-${section.id} origin${section.origin_id} ${section.css_classes?.join(' ')}`}
        style={{
          overflow: section.column_section?.id ? 'auto' : 'visible',
          display: (section.initial_visibility || window.wg.env === 'dashboard') ? '' : 'none',
        }}
        onClick={() => onClick(section)}
      >
        {textBlockElements}
      </section>
    </>
  );
}

function SectionContentOldModel({ section, onClick }) {
  const page = useSelector((state) => state.pageData.page);
  const allTextblocks = useSelector((state) => state.pageData.text_block);
  const allTextStyles = useSelector((state) => state.pageData.text_style);
  const allLinks = useSelector((state) => state.pageData.link);
  const allSections = useSelector((state) => state.pageData.section);
  const allButtonStyles = useSelector((state) => state.pageData.button_style);
  const textBlocks = filter(allTextblocks, (t) => t.section_content_id === section.id && !t.is_offline);
  const sectionRegex = /\(section:([0-9a-f]{24})\)/ig;
  const rawTextblockRegex = /<span class="raw-tb" data-text="([^"]*)"><\/span>/g;
  const normalTextblockRegex = /<span class="normal-tb" data-tag="([^"]*)" data-class="([^"]*)" data-text="([^"]*)"><\/span>/g;

  const sectionRef = useCallback((sectionNode) => {
    if (!sectionNode) {
      return;
    }

    // Replace raw textblock placeholders with real HTML
    if (sectionNode.innerHTML.match(rawTextblockRegex)) {
      sectionNode.innerHTML = sectionNode.innerHTML.replaceAll(rawTextblockRegex, (m, m2) => {
        // When using the dataset attribute, ampersands and double-quotes are the only things that are HTML-encoded.
        return m2.replaceAll('&quot;', '"').replaceAll('&amp;', '&');
      });

      // <script> tags that are injected into the DOM are basically ignored, so we need to run them manually. We don't
      // want to run them more than once, so we keep track of which sections we have processed.
      const scriptTags = sectionNode.querySelectorAll('script');
      if (scriptTags.length > 0) {
        if (typeof window.scriptsRun === 'undefined') {
          window.scriptsRun = [];
        }
        if (!window.scriptsRun.includes(section.id)) {
          window.scriptsRun.push(section.id);

          scriptTags.forEach((s) => {
            if (s.src) {
              const newS = document.createElement('script');
              newS.src = s.src;
              document.head.appendChild(newS);
            } else {
              try {
                eval(s.innerHTML);
              } catch (e) {
                console.log('Error when evaluating raw textblock:', e);
              }
            }
          });
        }
      }
    }

    if (sectionNode.innerHTML.match(normalTextblockRegex)) {
      sectionNode.innerHTML = sectionNode.innerHTML.replaceAll(normalTextblockRegex, (m, tag, klass, html) => {
        // When using the dataset attribute, ampersands and double-quotes are the only things that are HTML-encoded.
        return `<${tag} class="${klass}">${html.replaceAll('&quot;', '"').replaceAll('&amp;', '&')}</${tag}>`;
      });
    }

    // Don't bother doing the complicated stuff if there are no section macros.
    if (sectionNode.innerHTML.match(sectionRegex)) {
      // Process section macros in textblocks. This is a bit complicated because we need to render a React component
      // (the section) inside the textblock text, which is raw HTML.

      // First make an array of all text nodes inside the textblock.
      let node;
      const allNodes = [node];
      const walker = document.createTreeWalker(sectionNode, NodeFilter.SHOW_TEXT);
      while (node = walker.nextNode()) {
        allNodes.push(node);
      }

      // Then loop through each node
      allNodes.forEach((node) => {
        if (!node) {
          return;
        }

        const matches = [...(node.nodeType === 1 ? node.innerHTML : node.textContent).matchAll(sectionRegex)];
        matches?.forEach((match) => {
          const sectionId = match[1];

          const section = allSections.find((s) => s.id === sectionId || s.origin_id === sectionId);
          if (!section) {
            return;
          }

          const reactNode = document.createElement('div');
          const newNode = document.createElement('div');
          const root = createRoot(reactNode);
          root.render(
            <MacroContainer node={newNode}>
              <Provider store={createStore()}>
                {sectionObject(page, section, onClick)}
              </Provider>
            </MacroContainer>
          );

          const newId = `section${(Math.random() * 9999999999).toFixed()}`;
          newNode.innerHTML = (node.nodeType === 1 ? node.innerHTML : node.textContent).replace(match[0], `<div id="${newId}"></div>`);
          newNode.querySelector(`#${newId}`).replaceWith(reactNode);
          node.replaceWith(newNode);
        });
      });
    }

    replaceLinks(sectionNode, allLinks);
    stripButtonStyles(sectionNode);
  });

  // We need to remove the "style" attribute from all buttons set via the CMS. This is because we want the styles from
  // the Button Styles page in admin to override whatever is set in the textblock.
  const stripButtonStyles = (node) => {
    node.querySelectorAll('a[data-button-style-id]').forEach((a) => {
      const styleId = a.dataset.buttonStyleId;
      if (!styleId) {
        return;
      }

      const style = allButtonStyles?.find((s) => s.id === styleId);
      if (!style) {
        return;
      }

      a.querySelectorAll('button').forEach((b) => b.removeAttribute('style'));
      a.querySelectorAll('button > i').forEach((i) => i.removeAttribute('style'));
    });
  };

  const textBlockElements = textBlocks.map((textBlock) => {
    const textStyle = textBlock.text_style_id ? allTextStyles.find((s) => s.id === textBlock.text_style_id) : null;
    const media = textBlock.media ? <TextBlockMedia media={textBlock.media} /> : null;

    // Both the old platform and the Ruby version always add the line break, regardless of whether there is any text in
    // the textblock or not. But they add it inside the tag if there is text, and without the tag if there is no text.
    // If there is text, the linebreak is added above in the useEffect() call, and if there is no text we add it here
    // with the call to lineBreakElement().
    //
    // We also have a slightly hackish solution for raw textblocks. We sometimes use a system where we have a raw
    // textblock with an opening HTML tag (like <div>) and then a normal textblock with text and/or an image etc, and
    // then another raw textblock with a closing HTML tag (like </div>). If we try to assemble DOM elements with these
    // textblocks it won't work, because the browser will try to fix the mismatching tags and we will end up with
    // <div></div> [text and/or images] <div></div>. So we add a placeholder (.raw-tb) and we replace it with the real
    // HTML later, in the useEffect() call above.
    return (
      <Fragment key={textBlock.id}>
        {media}
        {isEmpty(textBlock.block_text) && lineBreakElement(textBlock.line_break)}
        {textBlock.type === 'raw' && (
          <span
            className="raw-tb"
            data-text={`<span class="tb">${macroReplacerString(textBlock.block_text)}${lineBreakText(textBlock.line_break)}</span>`}
          />
        )}
        {textBlock.type !== 'raw' && !isEmpty(textBlock.block_text) && (
          <span
            className="normal-tb"
            data-tag={textStyle ? textStyle.tag : 'span'}
            data-class={`tb style-${textBlock.text_style_id}`}
            data-text={macroReplacerString(textBlock.block_text) + lineBreakText(textBlock.line_break)}
          />
        )}
      </Fragment>
    );
  });

  return (
    <>
      <a className="anchor" id={`anchor${section.origin_id}`} />
      <section
        // The uniqueId below is because we need to force a re-render of the section when we are editing via the
        // dashboard. This is because of the hackish way we are replacing textblock elements using the above useCallback.
        key={uniqueId()}
        id={`section${section.id}`}
        ref={sectionRef}
        className={`SectionContent bp-${section.id} origin${section.origin_id} ${section.css_classes?.join(' ')}`}
        style={{
          overflow: section.column_section?.id ? 'auto' : 'visible',
          display: (section.initial_visibility || window.wg.env === 'dashboard') ? '' : 'none',
        }}
        onClick={() => onClick(section)}
      >
        {textBlockElements}
      </section>
    </>
  );
}

function SectionContent({ section, onClick }) {
  if (window.contentSectionModel === 2) {
    return <SectionContentNewModel section={section} onClick={onClick} />;
  }

  return <SectionContentOldModel section={section} onClick={onClick} />;
}

export default SectionContent;
