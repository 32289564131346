/* eslint-disable default-param-last */
import {
  SET_CTA_LOGS, DEL_CTA_LOG, SPAM_CTA_LOG, SINGLE_CTA_LOG,
} from '../actions';

const INITIAL_STATE = {
  isDeleted: 0,
  ctaLog: {},
  pagination: [],
  CTAReportChatData: [],
  CTAReportFormData: [],
  CTAReportReviewData: [],
  CTAReportNegativeReviewData: [],
  CTALogIncomingCallData: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case SET_CTA_LOGS:
    if (action.logType === 'CTALogFormSubmission') {
      return {
        ...state,
        CTAReportFormData: action.payload.cta_log.data,
        CTAReportFormPagination: action.payload.pagination,
      };
    }
    if (action.logType === 'CTALogChat') {
      return {
        ...state,
        CTAReportChatData: action.payload.cta_log.data,
        CTAReportChatPagination: action.payload.pagination,
      };
    }
    if (action.logType === 'CTALogReview') {
      return {
        ...state,
        CTAReportReviewData: action.payload.cta_log.data,
        CTAReportReviewPagination: action.payload.pagination,
      };
    }
    if (action.logType === 'CTALogNegativeReview') {
      return {
        ...state,
        CTAReportNegativeReviewData: action.payload.cta_log.data,
        CTAReportNegativeReviewPagination: action.payload.pagination,
      };
    }
    if (action.logType === 'CTALogIncomingCall') {
      return {
        ...state,
        CTALogIncomingCallData: action.payload.cta_log.data,
        CTALogIncomingCallPagination: action.payload.pagination,
      };
    }
    return state;
  case DEL_CTA_LOG:
    return {...state, isDeleted: state.isDeleted + 1};
  case SPAM_CTA_LOG:
    if (action.logType === 'CTALogFormSubmission') {
      const tempState = [...state.CTAReportFormData];
      tempState[action.payload].attributes.spam = !tempState[action.payload].attributes.spam;
      return {
        ...state,
        CTAReportFormData: tempState,
      };
    }
    return state;
  case SINGLE_CTA_LOG:
    return { ...state, CTAReportFormData: action.payload };
  default: return state;
  }
};
