import React, { useState } from 'react';
import { isEmpty, filter } from 'lodash-es';
import FullScreenSpinner from '../../FullScreenSpinner';
import { createStore } from '../../../rootStore';

const constantLabels = ['text', 'checkboxes', 'radio_button', 'file_upload'];

const inputType = {
  single_line_text: 'text',
  multi_line_text: 'textarea',
  short_text: 'text',
  long_text: 'textarea',
  radio_button: 'radio',
  checkboxes: 'checkbox',
  hidden: 'hidden',
  numeric: 'number',
  date: 'date',
  url: 'url',
  email: 'email',
};

const escapeHtml = (str) => str
  .replace(/&/g, '&amp;')
  .replace(/</g, '&lt;')
  .replace(/>/g, '&gt;')
  .replace(/"/g, '&quot;')
  .replace(/'/g, '&#039;');

function SectionForm({ section, onClick }) {
  const [submitting, setSubmitting] = useState(false);

  const nameForCheckboxRadioLabel = (item, formItemType) => {
    if (formItemType === 'checkboxes') {
      return `${item.id}[]`;
    }
    return item.id;
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.target.closest('form');

    if (!form.reportValidity()) {
      alert('Please fill out all required fields.');
      return false;
    }

    setSubmitting(true);

    const data = new FormData(form);
    if (form.querySelector('[name=g-recaptcha-response]') != null) {
      const token = form.querySelector('[name=g-recaptcha-response]').value;
      data.append('token', token);
    }
    data.append('action', 'uploadImages');
    const request = new XMLHttpRequest();
    request.open('POST', '/api/v1/after_form_submit', true);
    request.send(data);
    request.onload = () => {
      setSubmitting(false);

      if (request.status == 200) {
        const response = JSON.parse(request.response);
        if (response.data.length != 0) {
          if (response.data.dialog_box_message) {
            alert(response.data.dialog_box_message);
          }

          if (response.data.url) {
            window.top.location.href = response.data.url;
          }

          if (!response.data.dialog_box_message && !response.data.url) {
            // This form is not configured to do anything. We should provide at least some feedback so that the user
            // knows the submission was successful.
            alert('Thank you.\nYour message has been sent.');
          }
        }
        form.reset();
      } else {
        form.insertAdjacentHTML('afterbegin', `<div class='alert alert-danger'>${error.responseJSON.messages}</div>`);
      }
    };
  };

  const buttonHtml = () => {
    if (
      section.submit_button_type === 'button_style'
      && section.button_style_id
    ) {
      return (
        <span data-button-style-id={section.button_style_id}>
          <button
            onClick={onSubmit}
            alt="Submit Form"
          >
            {section.button_text}
          </button>
        </span>
      );
    } if (
      section.submit_button_type === 'image'
      && section.button_image_id
    ) {
      return (
        <>
          <input
            type="image"
            onClick={onSubmit}
            src={`${section.button_image_url}`}
            className="original-src"
            alt="Submit Form"
          />
          {section.button_hover_image_id && (
            <input
              type="image"
              onClick={onSubmit}
              src={`${section.button_hover_image_url}`}
              className="hover-image"
              alt="Submit Form"
            />
          )}
        </>
      );
    }

    return (
      <input
        type="submit"
        value={`${section.button_text}`}
        onClick={onSubmit}
      />
    );
  };

  const formSubmit = () => <div className={`form-submit-${section?.id}`}>{buttonHtml()}</div>;

  const dbFieldType = (type) => ({
    select_list: 'drop_down_list',
    radio_button: 'radio_button',
    checkboxes: 'checkboxes',
    long_text: 'multi_line_text',
  }[type] || 'single_line_text');

  const formItems = filter(section.form_items, (item) => item.section_form_id === section.id).map((item) => {
    const showLabel = (section.field_label_position === 'above_field'
      && item.type != 'hidden')
      || (section.field_label_position === 'inside_field'
        && constantLabels.includes(item.type));

    const formItemType = item.database_field
      ? dbFieldType(item.database_field?.field_type)
      : item.type;
    const placeHolder = section.field_label_position === 'inside_field' ? item.name : '';
    const requiredField = (section.add_to_database && item.database_field?.field_type === 'email') || item.is_required;

    const formItemTextType = item.database_field
      ? item.database_field?.field_type
      : item.type;
    const formItemInputType = inputType[formItemTextType];

    const addClosingTagForMultiple = (item, formItemType, requiredField) => {
      const values = item.database_field
        ? item.database_field.values
        : item.values;

      switch (formItemType) {
        case 'checkboxes':
        case 'radio_button':
          return (
            <div>
              {values.map((value, index) => {
                const id = `form-item-${item.id}-${index}`;

                return (
                  <div
                    key={value}
                    className={`form-check ${item.break_values_in_newline ? 'form-check-inline' : ''}`}
                  >
                    <input
                      className={`form-check-input ${requiredField ? 'required' : ''}`}
                      type={inputType[formItemType]}
                      name={nameForCheckboxRadioLabel(item, formItemType)}
                      id={id}
                      value={value}
                    />
                    <label className="form-check-label" htmlFor={id}>
                      {value}
                    </label>
                  </div>
                );
              })}
              {item.other_option && (
                <div
                  className={`form-check ${item.break_values_in_newline ? 'form-check-inline' : ''}`}
                >
                  <input
                    className={`form-check-input ${requiredField ? 'required' : ''}`}
                    type={inputType[formItemType]}
                    name={nameForCheckboxRadioLabel(item, formItemType)}
                    id={`form-item-${item.id}-other`}
                    value="Other"
                  />
                  <label className="form-check-label" htmlFor={`form-item-${item.id}-other`}>
                    Other
                  </label>
                </div>
              )}
            </div>
          );
        case 'drop_down_list':
          return (
            <select name={item.id} className="form-control">
              {section.field_label_position === 'inside_field' && (
                <option value="" selected disabled hidden>
                  {escapeHtml(item.name)}
                </option>
              )}
              {values.map((e) => (
                <option key={e} value={e}>
                  {e}
                </option>
              ))}
            </select>
          );
        case 'text':
          return values.map((e) => (
            <span key={e}>
              {e}
              <br />
            </span>
          ));
      }
    };

    const renderFormItemInput = () => {
      switch (formItemType) {
        case 'single_line_text':
          return (
            <input
              type={formItemInputType}
              name={item.id}
              className="form-control"
              placeholder={placeHolder}
              required={requiredField}
            />
          );
        case 'multi_line_text':
          return (
            <textarea
              name={item.id}
              className="form-control"
              placeholder={placeHolder}
              required={requiredField}
            />
          );
        case 'hidden':
          return (
            <input type="hidden" name={item.id} value={item.default_value} />
          );
        case 'file_upload':
          return (
            <input
              id={item.id}
              name={`[file][${item.id}][]`}
              type="file"
              className="form-control"
              multiple
            />
          );
        case 'date':
          return (
            <input
              type="date"
              name={item.id}
              className="form-control"
              required={requiredField}
            />
          );
        default:
          return addClosingTagForMultiple(item, formItemType);
      }
    };

    return (
      <div key={item.id} className={`form-item-${item.id}`}>
        {showLabel && (
          <div className="form-label">
            <label>
              {item.name}
              {requiredField && <span className="requiredMark">*</span>}
            </label>
          </div>
        )}
        <div className="form-item-value">{renderFormItemInput()}</div>
      </div>
    );
  });

  const privacyPolicy = () => {
    const store = createStore();
    const { pageData: { site } } = store.getState();

    return (
      <div className="privacy-policy-link tb">
        <a href="/?Action=PrivPol" id={`privacy-policy-${section.id}`} target="_blank">
          Privacy Policy
        </a>
        {!isEmpty(site?.recaptcha_site_key) && (
          <>
            {' '}
            | This site is protected by reCAPTCHA and the Google
            {' '}
            <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
              Privacy Policy
            </a>
            {' '}
            and
            {' '}
            <a href="https://policies.google.com/terms" target="_blank" rel="noreferrer">
              Terms of Service
            </a>
            {' '}
            apply.
          </>
        )}
      </div>
    );
  };

  return (
    <>
      {submitting && <FullScreenSpinner />}
      <a className="anchor" id={`anchor${section.origin_id}`} />
      <section
        id={`section${section.id}`}
        className={`SectionForm bp-${section.id} origin${section.origin_id} ${section.css_classes?.join(' ')}`}
        style={{ display: (section.initial_visibility || wg.env === 'dashboard') ? '' : 'none' }}
        onClick={() => onClick(section)}
      >
        <form
          method="POST"
          action="/api/v1/after_form_submit"
          encType="multipart/form-data"
          name={`form${section.id}`}
          id={`form${section.id}`}
          className={`form-section labels-${section.field_label_position}`}
          onSubmit={onSubmit}
        >
          <input type="hidden" name="formId" value={section.id} />
          {formItems}
          {formSubmit()}
        </form>
        {section.include_privacy_policy && privacyPolicy()}
      </section>
    </>
  );
}

export default SectionForm;
