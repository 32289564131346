import React, { useRef, useEffect } from 'react';

function SectionComponent({ section, onClick }) {
  const ref = useRef();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    if (!section.full_size) {
      return;
    }

    const component = ref.current.querySelector(`#component-section-${section.id}`);

    if (!component) {
      return;
    }

    const wrapper = component.closest('.wrapper');
    const sector = component.closest('.sector');

    if (!wrapper || !sector) {
      // We may be in the admin dashboard.
      return;
    }

    wrapper.style.margin = 0;
    wrapper.style.padding = 0;

    sector.style.width = '100%';
    sector.style.margin = 0;
    sector.style.padding = 0;
  }, [ref]);

  return (
    <div
      className="component-section-wrapper"
      id={`component-section-wrapper-${section.id}`}
      ref={ref}
      onClick={onClick}
    />
  );
}

export default SectionComponent;
