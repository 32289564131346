/* eslint-disable default-param-last */
import { SET_TEMPLATES, DUPLICATE_TEMPLATE, DELETE_TEMPLATE } from '../../actions';

const INITIAL_STATE = {
  templates: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case SET_TEMPLATES:
    return { ...state, templates: action.payload };
  case DUPLICATE_TEMPLATE:
    return { ...state, templates: [...state.templates, action.payload] };
  case DELETE_TEMPLATE:
    return { ...state, templates: state.templates.filter((template) => template.id !== action.payload.id)};
  default: return state;
  }
};
