/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS = 'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

/* Site Selection */
export const SITE_SELECTED = 'SITE_SELECTED';
export const GET_SELECTED_SITE = 'GET_SELECTED_SITE';
export const SET_SITES_LIST = 'SET_SITES_LIST';

/* Spinner */
export const SPIN_COUNT_INC = 'SPIN_COUNT_INC';
export const SPIN_COUNT_DEC = 'SPIN_COUNT_DEC';

/* Fetch database fields */
export const FETCH_DATABASE_FIELDS = 'FETCH_DATABASE_FIELDS';
export const ADD_DATABASE_FIELD = 'ADD_DATABASE_FIELD';
export const DEL_DATABASE_FIELD = 'DEL_DATABASE_FIELD';
export const SET_DATABASE_FIELD = 'SET_DATABASE_FIELD';
export const SET_DATABASE_FIELDS = 'SET_DATABASE_FIELDS';
export const UPDATE_DATABASE_FIELD = 'UPDATE_DATABASE_FIELD';

/* Fetch saved searche data */
export const FETCH_SAVED_SEARCHES_DATA = 'FETCH_SAVED_SEARCHES_DATA';
export const DELETE_SAVED_SEARCH = 'DELETE_SAVED_SEARCH';

/* Delete member */
export const DELETE_MEMBER = 'DELETE_MEMBER';

/* Get user detail */
export const GET_USER_DETAILS = 'GET_USER_DETAILS';

/* Sector type constants */
export const ADD_SECTOR = 'ADD_SECTOR';

/* Get media data */
export const UPDATE_CONTENT_TYPE_SECTION = 'UPDATE_CONTENT_TYPE_SECTION';

/* Site Settings Actions */
export const SET_SITE = 'SET_SITE';

/* Trash Actions */
export const GET_TRASH_LIST = 'GET_TRASH_LIST';
export const GET_RSTORE_OBJ = 'GET_RSTORE_OBJ';
export const GET_ACTIONMESSAGE = 'GET_ACTIONMESSAGE';
export const SHOW_REPLACE_SKIP_POPUP = 'SHOW_REPLACE_SKIP_POPUP';

/* Image Gallery */
export const GET_TEXT_STYLE = 'GET_TEXT_STYLE';

/* Set Error */
export const SET_ERROR = 'SET_ERROR';

/* CTA Reports Actions */
export const SET_CTA_LOGS = 'SET_CTA_LOGS';
export const DEL_CTA_LOG = 'DEL_CTA_LOG';
export const SPAM_CTA_LOG = 'SPAM_CTA_LOG';
export const SINGLE_CTA_LOG = 'SINGLE_CTA_LOG';

/* Automated Reports */
export const SET_AUTO_REPORT = 'SET_AUTO_REPORT';

/* Templates */
export const SET_TEMPLATES = 'SET_TEMPLATES';
export const DUPLICATE_TEMPLATE = 'DUPLICATE_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';

/* Component Categories */
export const SET_COMPONENT_CATEGORIES = 'SET_COMPONENT_CATEGORIES';
export const SET_COMPONENT_CATEGORY = 'SET_COMPONENT_CATEGORY';
export const UPDATE_COMPONENT_CATEGORY = 'UPDATE_COMPONENT_CATEGORY';
export const CREATE_COMPONENT_CATEGORY = 'CREATE_COMPONENT_CATEGORY';
export const DELETE_COMPONENT_CATEGORY = 'DELETE_COMPONENT_CATEGORY';

/* Components */
export const SET_COMPONENTS = 'SET_COMPONENTS';
export const SET_COMPONENT = 'SET_COMPONENT';
export const UPDATE_COMPONENT = 'UPDATE_COMPONENT';
export const DELETE_COMPONENT = 'DELETE_COMPONENT';
export const DUPLICATE_COMPONENT = 'DUPLICATE_COMPONENT';

/* Style Override */
export const SET_STYLE_OVERRIDE = 'SET_STYLE_OVERRIDE';
export const CREATE_STYLE_OVERRIDE = 'CREATE_STYLE_OVERRIDE';
export const UPDATE_STYLE_OVERRIDE = 'UPDATE_STYLE_OVERRIDE';
export const DELETE_STYLE_OVERRIDE = 'DELETE_STYLE_OVERRIDE';
export const UPDATE_STYLE_OVERRIDE_DATA = 'UPDATE_STYLE_OVERRIDE_DATA';

export const HIDE_SIDEBAR = 'HIDE_SIDEBAR';

/* Xero */
export const XERO_CALLBACK = 'XERO_CALLBACK';
export const XERO_CONNECTION = 'XERO_CONNECTION';

export const setError = (payload) => ({
  type: SET_ERROR,
  payload,
});

export const actionType = (type, payload) => ({
  type,
  payload,
});

export * from './menu/actions';
export * from './settings/actions';
export * from './spinner/actions';
export * from './dataCenter/actions';
