import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import spinner from './spinner/reducer';
import dataCenterReducer from './dataCenter/reducer';
import memberReducer from './members/reducer';
import sectorReducer from './sector/reducer';
import sitesReducer from './settings/siteSetting/reducer';
import trashReducer from './settings/trash/reducer';
import CTAReportsReducer from './ctaReports/reducer';
import automatedReportReducer from './automated-report/reducer';
import templatesReducer from './designOptions/templates/reducer';
import componentCategoriesReducer from './designOptions/componentCategories/reducer';
import componentsReducer from './designOptions/components/reducer';
import styleOverrideReducer from './designOptions/styleOverride/reducer';
import sidebarReducer from './sideBar/reducer';
import xeroReducer from './settings/xero/reducer';
import searchTextReducer from './searchText/reducer';
import { instanceSlice } from '../api/apiSlice';

const reducers = combineReducers({
  menu,
  spinner,
  settings,
  xeroReducer,
  sitesReducer,
  trashReducer,
  memberReducer,
  sectorReducer,
  instanceReducer: instanceSlice.reducer,
  templatesReducer,
  CTAReportsReducer,
  dataCenterReducer,
  styleOverrideReducer,
  automatedReportReducer,
  componentCategoriesReducer,
  componentsReducer,
  sidebarReducer,
  searchTextReducer,
});

export default reducers;
